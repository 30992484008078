import { FiFolderPlus } from "react-icons/fi";
import { FiFileText } from "react-icons/fi";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import image from 'assets/image/help.gif'

import EditLMSQuizBuilderPage from "./components/quizBuilder/EditLMSQuizBuilderPage";
import EditLMSQuizSettingsPage from "./components/quizSettings/EditLMSQuizSettingsPage";
import EditLMSQuizParticipantsPage from "./components/quizParticipants/EditLMSQuizParticipantsPage";

import QuizGeneralSettings from "./components/quizSettings/components/generalSettings/QuizGeneralSettings";
import QuizProduct from "./components/quizSettings/components/quizProduct/QuizProduct";
import QuizSlug from "./components/quizSettings/components/QuizSlug/QuizSlug";
import QuizInstructor from "./components/quizSettings/components/quizInstructor/QuizInstructor";

import JoinedParticipants from "./components/quizParticipants/joinedParticipants/JoinedParticipants";
import InvitedParticipants from "./components/quizParticipants/invitedParticipants/InvitedParticipants";

import { lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";
import QuizCertificate from "./components/quizSettings/components/certificate";

export const lmsQuizTabConst = {
    BUILDER: {
        label: "Builder",
        value: "builder",
        pagePath: "/builder",
        routePath: "builder",
        component: EditLMSQuizBuilderPage
    },
    SETTINGS: {
        label: "Settings",
        value: "settings",
        pagePath: "/settings",
        routePath: "settings",
        component: EditLMSQuizSettingsPage
    },
    PARTICIPANTS: {
        label: "Participants",
        value: "participants",
        pagePath: "/participants",
        routePath: "participants",
        component: EditLMSQuizParticipantsPage
    }
}

export const quizSettingsNavigationOptions = {
    GENERAL: {
        label: "General settings",
        value: "general",
        pagePath: "/general",
        routePath: "general",
        to: "settings/general",
        component: QuizGeneralSettings
    },
    PRODUCT: {
        label: "Pricing (Product)",
        value: "product",
        pagePath: "/product",
        routePath: "product",
        to: "settings/product",
        component: QuizProduct
    },
    SLUG: {
        label: "Sharable link",
        value: "slug",
        pagePath: "/slug",
        routePath: "slug",
        to: "settings/slug",
        component: QuizSlug
    },
    INSTRUCTOR: {
        label: "Instructor",
        value: "instructor",
        pagePath: "/instructor",
        routePath: "instructor",
        to: "settings/instructor",
        component: QuizInstructor
    },
    CERTIFICATE: {
        label: "Certificate",
        value: "certificate",
        pagePath: "/certificate",
        routePath: "certificate",
        to: "settings/certificate",
        component: QuizCertificate
    },
}

export const quizParticipantsNavigationOptions = {
    JOINED: {
        label: "Joined",
        value: "joined",
        pagePath: "/joined",
        routePath: "joined",
        to: "participants/joined",
        component: JoinedParticipants
    },
    INVITED: {
        label: "Invited",
        value: "invited",
        pagePath: "/invited",
        routePath: "invited",
        to: "participants/invited",
        component: InvitedParticipants
    },
}

export const quizInstructorHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    PERMISSION: {
        label: "Permission",
        value: "permission"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const quizInstructorMenuItemConst = {
    MAKE_DEFAULT: {
        label: "Make Default",
        value: "make_default"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}


export const quizStudentHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    JOINING_FLOW: {
        label: "Joining Flow",
        value: "joining_flow"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    IS_ACTIVE: {
        label: "Is Active",
        value: "is_active"
    },
    JOINED_ON: {
        label: "Joined on",
        value: "joined_on"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const quizStudentInvitationHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    // INVITE_TO: {
    //     label: "Invite to",
    //     value: "invite_to"
    // },
    // INVITE_EMAIL_SENT: {
    //     label: "Invite email sent",
    //     value: "invite_email_sent"
    // },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE_JOINED: {
        label: "Date Joined",
        value: "date_joined"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const quizStudentMenuItemsConst = {
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "In Active",
        value: "inactive"
    },
    BLOCKED: {
        label: "Block",
        value: "blocked"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const quizStudentInviteMenuItemsConst = {
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const lmsQuizTooltips = {
    thumbnailTooltip: "Find a free image or upload for thumbnail.",
    tagsTooltip: "Type relevant keywords.",
    publishedInviteBtnTooltip: "Send quiz invitations through email.",
    disabledInviteBtnTooltip: "Publish this quiz to invite participants.",
    slugPublicViewTooltip: "Public View is for published content only."
}

export const quizSectionIconList = {
    BULK_QUESTION: {
        label: "Bulk Questions",
        value: "bulk_questions",
        icon: <AutoFixHighIcon style={{ fontSize: 18 }} className={'text-yellow-200'} />,
        tooltip: "Create questions with ai"
    },
    QUESTION: {
        label: "Question",
        value: "question",
        icon: <FiFolderPlus className={"text-xl text-text-700 hover:text-primary-dark group-hover:text-primary-dark"} />,
        tooltip: "Add Question"
    },
    ARTICLE: {
        label: "Article",
        value: "article",
        icon: <FiFileText className={"text-xl text-text-700 hover:text-primary-dark group-hover:text-primary-dark"} />,
        tooltip: "Add Article"
    },
    // VIDEO: {
    //     label: "Video",
    //     value: "video",
    //     icon: <MdOutlineOndemandVideo className={"text-xl text-text-700 hover:text-primary-dark group-hover:text-primary-dark"} />,
    //     tooltip: "Add Video"
    // },
    // IMAGE: {
    //     label: "Image",
    //     value: "image",
    //     icon: <BsImage className={"text-xl text-text-700 hover:text-primary-dark group-hover:text-primary-dark"} />,
    //     tooltip: "Add Image"
    // },
}

export const getTotalQuizQuestionsCount = (quizSections) => {
    if (quizSections?.length > 0) {
        let totalQuestionCount = 0;
        for (const quizSection of quizSections) {
            if (quizSection?.quiz_categories?.length > 0) {
                for (const sectionCategory of quizSection?.quiz_categories) {
                    if (sectionCategory?.type === lmsQuizCategoryEnum?.QUESTION?.value) {
                        totalQuestionCount++;
                    }
                }
            }
        }
        return totalQuestionCount;
    }
}

export const searchAlreadyAddedCategory = (quizSections = [], targetQuizCategoryId = null) => {

    if (!quizSections || (quizSections?.length === 0) || !targetQuizCategoryId) return;

    if (quizSections?.length > 0) {
        for (const quizSection of quizSections) {
            if (quizSection?.quiz_categories?.length > 0) {
                for (const sectionCategory of quizSection?.quiz_categories) {
                    if ((sectionCategory?.type === lmsQuizCategoryEnum?.QUESTION?.value) && sectionCategory?.question?.question?.id === targetQuizCategoryId) {
                        alert(`Uh-oh! You've already added this question in ${quizSection?.title}. No duplicates, please!`)
                        return true;
                    }
                    if ((sectionCategory?.type === lmsQuizCategoryEnum?.ARTICLE?.value) && sectionCategory?.article?.article?.article_id === targetQuizCategoryId) {
                        alert(`Uh-oh! You've already added this article in ${quizSection?.title}. No duplicates, please!`)
                        return true;
                    }
                    if ((sectionCategory?.type === lmsQuizCategoryEnum?.VIDEO?.value) && sectionCategory?.resource?.resource?.id === targetQuizCategoryId) {
                        alert(`Uh-oh! You've already added this video in ${quizSection?.title}. No duplicates, please!`)
                        return true;
                    }
                    if ((sectionCategory?.type === lmsQuizCategoryEnum?.IMAGE?.value) && sectionCategory?.resource?.resource?.id === targetQuizCategoryId) {
                        alert(`Uh-oh! You've already added this image in ${quizSection?.title}. No duplicates, please!`)
                        return true;
                    }
                }
            }
        }
    }

    return false;
}


export const steps = [
    {
        title: <h2 className="text-xl font-medium text-primary-dark">Let's begin our journey!</h2>,
        content: <div className="flex flex-col items-center justify-center gap-3">
            <p className="text-sm text-text-600">Explore the World: Interactive Website Guided Tour</p>
            <img src={image} alt='empty' className='w-32 aspect-square hover:scale-105 ease-linear duration-300' />
        </div>,
        placement: 'center',
        target: 'body',
    },
    {
        target: '.editTitle',
        title: <h2 className="text-lg font-medium text-text-900">Edit Quiz Topic</h2>,
        content: 'Define the quiz subject.',
    },
    {
        target: '.addTitleWithAi',
        title: <h2 className="text-lg font-medium text-text-900">Edit or create quiz title with AI</h2>,
        content: 'This is my awesome feature!',
    },
    {
        target: '.addTextWithAi',
        title: <h2 className="text-lg font-medium text-text-900">Edit or create quiz description with AI</h2>,
        content: 'This another awesome feature!',
    },
    {
        target: '.addSection',
        title: <h2 className="text-lg font-medium text-text-900">Add new quiz section</h2>,
        content: 'This another awesome feature!',
    },
    {
        target: '.addAiQuestion',
        title: <h2 className="text-lg font-medium text-text-900">Create quiz questions with AI</h2>,
        content: 'This another awesome feature!',
    },
    {
        target: '.uploadThumbnail',
        title: <h2 className="text-lg font-medium text-text-900">Change quiz thumbnail</h2>,
        content: 'This another awesome feature!',
    },
    {
        target: '.addTag',
        title: <h2 className="text-lg font-medium text-text-900">Add or edit quiz tags</h2>,
        content: 'This another awesome feature!',
    },
    {
        target: '.publishBtn',
        title: <h2 className="text-lg font-medium text-text-900">Publish and share your live quiz</h2>,
        content: 'This another awesome feature!',
    },
];