import { useEffect } from 'react'
import { useTitle } from 'hooks/useTitle'
import { RiAccountPinBoxFill } from "react-icons/ri";

import CtaFormStep from './_components/cta-form-step'

import { setPageInfo } from 'redux/pageInfo/pageInfo.request'

import { useAppDispatcher, useAppState } from 'hooks/useStore'

import { pagesInfo } from 'utils/pagesInfo'
import { useLocation, useNavigate } from 'react-router-dom'


const FeatureGetYouAccount = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const session = useAppState(state => state.auth.session)

    const [title, setTitle] = useTitle()

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const redirect_url = new URL(window.location.href)

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.FEATURE_GET_YOUR_ACCOUNT))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Educators - Access Powerful Teaching Tools",
            description: "Register to access advanced tools designed to enhance your teaching. Simplify your process, engage students, and maximise your educational impact."
        })
    }, [currentPageInfo])

    if (session?.isLoggedIn) {
        if (!!redirect_url?.searchParams?.get("redirect_url")) {
            window.open(redirect_url?.searchParams?.get("redirect_url"), "_self")
        } else {
            navigate(from, { replace: true });
        }
    }

    return (
        <main className="relative h-screen bg-background-black">
            <div className="absolute inset-0 flex overflow-hidden">
                <div className="absolute m-5 p-10 rounded-full text-background-black bg-gray-800 bg-blend-saturation opacity-50">
                    <RiAccountPinBoxFill size={180} />
                </div>
                <div
                    className="w-3/4 lg:w-2/3 h-full"
                    style={{ clipPath: 'polygon(0 0, 100% 0, 80% 100%, 0% 100%)' }}
                ></div>
                <div
                    className="w-1/4 lg:w-1/2 h-full bg-primary-main"
                    style={{ clipPath: 'polygon(20% 0, 100% 0, 100% 100%, 0% 100%)' }}
                ></div>
            </div>
            <div className="relative flex flex-col lg:flex-row h-full max-w-7xl mx-auto p-5">
                <div className="flex flex-col justify-center items-start p-10 gap-5 text-white mt-48 lg:mt-0">
                    <h1 className='font-bodyPri font-bold text-white drop-shadow-5xl text-3xl md:text-4xl lg:text-5xl leading-8 lg:leading-[1.2em]'>
                        Unlock Powerful Tools for <span className='text-secondary-main'>Educators</span> & Small <span className='text-secondary-main'>Businesses</span>
                    </h1>
                    <p className='font-bodyPri font-medium text-white text-base md:text-md'>Enhance Your Teaching with Advanced Features</p>
                </div>

                <div className="flex justify-center items-center">
                    <div className="w-full lg:w-[42rem]">
                        <CtaFormStep />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default FeatureGetYouAccount