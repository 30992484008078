import { motion } from 'framer-motion'
import image from 'assets/image/link-hub.png'
import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { setModal } from 'redux/local/local.slice'
import { modalConst } from 'redux/local/local.const'

const CreateLinkHug = () => {
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const handleCreateLinks = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LINK_HUG_MODAL.key]: {
                ...modal[modalConst.CREATE_LINK_HUG_MODAL.key],
                isVisible: true,
                title: "Create Unique Link",
            }
        }))
    }

    return (
        <div className='flex flex-col justify-center items-center gap-5 font-bodyPri'>
            <img
                className='h-40 aspect-square object-contain'
                alt='no item found'
                src={image}
            />
            <motion.button
                whileHover={{ scale: 1.09 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleCreateLinks}
                className='h-12 px-5 flex justify-center items-center bg-primary-main text-white rounded hover:bg-secondary-main font-medium'
            >
                Create a Link
            </motion.button>
        </div>
    )
}

export default CreateLinkHug
