import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import image from 'assets/image/google-calendar.png'
import authService from "redux/auth/auth.service";

import { pagesInfo } from "utils/pagesInfo";
import Error from "components/toastMessageCard/error";
import Success from "components/toastMessageCard/success";
import { getMyProfile } from "redux/user/user.request";
import { useAppDispatcher } from "hooks/useStore";

const searchParamsInfo = {
  code: {
    key: "code"
  }
}

const GoogleCalendarConsentPage = () => {

  const [calendarAuthInfo, setCalendarAuthInfo] = useState({
    isLoading: false,
    success: null,
    error: null,
  })
  const dispatcher = useAppDispatcher()
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const createGoogleCalendarIntegrationAuth = async (authorizationCode) => {
    setCalendarAuthInfo((s) => ({ ...s, isLoading: true }))

    try {
      const requestData = {
        body: { code: authorizationCode }
      }

      const response = await authService.createGoogleCalendarIntegrationAuth(requestData)
      if (response.status === 200) {
        setCalendarAuthInfo((s) => ({ ...s, success: response.data.message }))
      } else {
        throw new Error(response)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setCalendarAuthInfo((s) => ({ ...s, error: "Failed to connect to Google Calendar. Please try again." }))
    } finally {
      setCalendarAuthInfo((s) => ({ ...s, isLoading: false }))
    }
  }

  useEffect(() => {
    if (queryParameters.get(searchParamsInfo.code.key)) {
      createGoogleCalendarIntegrationAuth(queryParameters.get(searchParamsInfo.code.key));
    }
  }, [queryParameters.get(searchParamsInfo.code.key)]);

  const onHandleGotIt = () => {
    dispatcher(getMyProfile())
    navigate(pagesInfo.SCHEDULE.pagePath)
  }

  return (
    <div className="flex flex-col gap-5 flex-1 items-center justify-center bg-white">
      {calendarAuthInfo?.isLoading &&
        <div className="flex items-center justify-center">
          <AiOutlineLoading3Quarters className="animate-spin mr-2" size={26} />
          <span className="text-xl font-bold text-gray-600">{"Processing..."}</span>
        </div>
      }
      {calendarAuthInfo?.error &&
        <Error
          title={"Oh no!"}
          subTitle={calendarAuthInfo?.error}
          buttonText={"Try again"}
          onButtonClick={() => navigate("/settings/general/calendar")}
        />
      }
      {calendarAuthInfo?.success &&
        <Success
          title={"Connected!"}
          icon={image}
          subTitle={calendarAuthInfo?.success}
          buttonText={"Got it"}
          onButtonClick={onHandleGotIt}
        />
      }
    </div>
  );
};

export default GoogleCalendarConsentPage;
