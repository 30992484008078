import { AiOutlineEnter } from "react-icons/ai";
import { GrAddCircle } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";

export const actionButtons = {
    DELETE: {
        value: "delete",
        tooltip: "delete",
        icon: <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
    },
    ADD: {
        value: "add",
        tooltip: "Add",
        icon: <GrAddCircle className={"text-xl text-text-700 hover:text-primary-dark"} />
    },
    APPLY_TO_ALL: {
        value: "apply_to_all",
        tooltip: "Apply to All",
        icon: <AiOutlineEnter className={"text-xl text-text-700 hover:text-primary-dark"} />
    }
}

export const sortTimeSlots = (timeSlot1, timeSlot2) => {
    return timeSlot2?.startTime < timeSlot1?.startTime ? 1 : -1
}

export const sortDateTimeSlots = (dateTime1, dateTime2) => {
    return dateTime2?.date < dateTime1?.date ? 1 : -1
}