import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';
import CourseCardTwo from 'components/courseCardTwo/CourseCardTwo';

import { createCourseFavoriteDetail, deleteCourseFavoriteDetail } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const TutorCourseList = () => {
    const { user } = useAppState((s) => s.user)
    const { publicTutorCourseList, addCourseFavDetail, deleteCourseFavDetail } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const { userId } = useParams()

    return (
        <div className={"flex flex-wrap items-center justify-center md:justify-start gap-x-3 gap-y-5"}>
            {publicTutorCourseList?.isLoading && <ComponentLoader isLoading={publicTutorCourseList?.isLoading} />}
            {(!publicTutorCourseList?.isLoading && publicTutorCourseList?.data?.records > 0)
                && publicTutorCourseList?.data?.results?.map((course, idx) => (
                    <div className={"w-64"}>
                        <CourseCardTwo
                            key={idx}
                            course={course}
                            isFav={user?.user?.favoriteCourses?.filter((favCourse) =>
                                favCourse.courseId === course.id).length > 0 ? true : false}
                            handleFav={(courseId) => {
                                if (addCourseFavDetail?.isLoading || deleteCourseFavDetail?.isLoading) return;
                                const favoriteCourses = user?.user?.favoriteCourses?.filter((favCourse) =>
                                    favCourse.courseId === courseId)
                                if (favoriteCourses?.length > 0) {
                                    dispatcher(deleteCourseFavoriteDetail({ userId: user?.user?.userId, courseId: courseId }))
                                }
                                else {
                                    dispatcher(createCourseFavoriteDetail({ userId: user?.user?.userId, courseId: courseId }))
                                }
                            }}
                        />
                    </div>
                ))}
            {(publicTutorCourseList?.data && publicTutorCourseList?.data.totalPages > 1) && (
                <div className="w-full flex justify-center items-center">
                    <Pagination
                        page={publicTutorCourseList?.data?.page}
                        totalPages={publicTutorCourseList?.data?.totalPages}
                        onChangePage={(page) => navigate(`${pagesInfo?.TUTOR_COURSE?.pagePath}/${userId}/classes?pn=${page}`)}
                    />
                </div>
            )}
            {(publicTutorCourseList?.data?.records === 0 || publicTutorCourseList.message) &&
                <div className='w-full h-full bg-white p-5 rounded-lg text-center text-base font-bodyPri tracking-wide'>
                    {publicTutorCourseList.message || "No Classes Found!"}
                </div>
            }
        </div>
    )
}

export default TutorCourseList;