export const pageHeading = {
    heading: "Create Certificate",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}
export const studentPageHeading = {
    heading: "My Certificate",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const certificateListHeader = [
    "User Name",
    "Category",
    "Date",
    "Certificate #",
    "Method",
    "Status",
    "Action"
]

export const studentCertificateListHeader = [
    "Issued for",
    "Category",
    "Date",
    "Certificate #",
    "Download"
]

export const certificateMenuItems = {
    EDIT: {
        label: "Edit",
        value: "edit"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    },
    CLONE: {
        label: "Clone",
        value: "clone"
    },
    SHARE: {
        label: "Share",
        value: "share"
    },
}

export const selectCategory = {
    // live_class: {
    //     label: "Live Class",
    //     value: "live_class"
    // },
    lms_course: {
        label: "Course",
        value: "lms_course"
    },
    quiz: {
        label: "Quiz",
        value: "quiz"
    }
}

export const certificateStatusEnum = {
    draft: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    issued: {
        value: 'issued',
        label: 'Issue',
        lightColor: 'green-100',
        darkColor: 'green-500',
    }
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    status: {
        key: "status"
    }
}

export const getCertificateListPayload = async (searchQueryParams) => {

    let requestDataPayload = {
        page: searchQueryParams.get(searchParamsInfo.page.key) || 1,
        records: searchQueryParams.get(searchParamsInfo.records.key) || 10
    }
    if (!!searchQueryParams.get(searchParamsInfo.status.key)) {
        requestDataPayload[searchParamsInfo.status.key] = searchQueryParams.get(searchParamsInfo.status.key)?.replaceAll("-", "_")
    }

    return requestDataPayload;
}