import { useEffect, useMemo } from "react";

import ComponentLoader from "components/loader/ComponentLoader";

import AppointmentProfile from "pages/auth/bookings/detail/AppointmentProfile";
import CourseProfile from "pages/auth/bookings/detail/CourseProfile";
import BookingAppointment from "pages/auth/bookings/detail/BookingAppointment";
import BookingCourse from "pages/auth/bookings/detail/BookingCourse";
import RepeatBookingDetail from "pages/auth/bookings/detail/RepeatBookingDetail";
import BookingSchedule from "pages/auth/bookings/detail/BookingSchedule";
import BookingLogs from "pages/auth/bookings/detail/BookingLogs";
import CancelBookingDetail from "pages/auth/bookings/detail/CancelBookingDetail";

import { getTutorBookingDetail, getUserBookingDetail } from "redux/booking/booking.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearBookingDetailPayload, resetUserBookingDetail, resetTutorBookingDetail } from "redux/booking/booking.slice";
import { userRoles } from "redux/auth/auth.const";
import { bookingOfferingTypeEnum, bookingStatusEnum } from 'redux/booking/booking.const';

import { timeZone } from 'utils/dateTime.utils';
import { cn } from "utils/cn.utils";

export const BookingSideOverContent = () => {
  const { bookingDetail, userBookingDetail, tutorBookingDetail, addBookingSessionDetail } = useAppState((s) => s.booking)
  const { user } = useAppState((s) => s.user)
  const { locals } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher()

  const newBookingDetail = useMemo(() => (locals?.userRole === userRoles?.TUTOR?.value) ? tutorBookingDetail : userBookingDetail, [locals?.userRole, tutorBookingDetail, userBookingDetail])

  useEffect(() => {
    if (bookingDetail?.bookingDetailPayload?.bookingId) {
      if (locals?.userRole === userRoles?.TUTOR?.value) {
        dispatcher(getTutorBookingDetail(bookingDetail?.bookingDetailPayload?.bookingId, user?.user?.tutor?.tutorId, { timeZone: timeZone }))
      }
      if (locals?.userRole === userRoles?.STUDENT?.value) {
        dispatcher(getUserBookingDetail(bookingDetail?.bookingDetailPayload?.bookingId, user?.user?.userId, { timeZone: timeZone }))
      }
    }

    return () => {
      dispatcher(setClearBookingDetailPayload())
      dispatcher(resetUserBookingDetail())
      dispatcher(resetTutorBookingDetail())
    }
  }, [bookingDetail?.bookingDetailPayload, addBookingSessionDetail?.data?.result])

  return (
    <div className={"p-5 h-screen sm:px-5 max-w-2xl md:max-w-xl lg:max-w-lg"}>
      <ComponentLoader isLoading={userBookingDetail?.isLoading || tutorBookingDetail?.isLoading} />
      {(newBookingDetail?.data?.result && !userBookingDetail?.isLoading && !tutorBookingDetail?.isLoading) && (
        <div className={cn(
          "flex flex-col w-full space-y-6 pb-5",
          // "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
          // "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
          // "min-h-[80vh] overflow-hidden overflow-x-hidden overflow-y-scroll space-y-6 transition ease-in-out delay-150 duration-300"
        )}>
          {(newBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum?.APPOINTMENT?.value) &&
            <AppointmentProfile newBookingDetail={newBookingDetail} />
          }
          {(newBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum?.COURSE?.value) &&
            <CourseProfile newBookingDetail={newBookingDetail} />
          }
          {(newBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum?.APPOINTMENT?.value) &&
            <BookingAppointment newBookingDetail={newBookingDetail} />
          }
          {(newBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum?.COURSE?.value) &&
            <BookingCourse newBookingDetail={newBookingDetail} />
          }
          {(locals?.userRole === userRoles?.STUDENT?.value) &&
            <RepeatBookingDetail newBookingDetail={newBookingDetail} />
          }
          <div className={"w-full bg-text-400 h-0.5 mx-auto"} />
          <BookingSchedule newBookingDetail={newBookingDetail} />
          <div className={"w-full bg-text-400 h-0.5 mx-auto"} />
          <BookingLogs newBookingDetail={newBookingDetail} />
          {((locals?.userRole === userRoles?.STUDENT?.value) && ([bookingStatusEnum?.PENDING?.value, bookingStatusEnum?.CONFIRMED?.value]?.includes(newBookingDetail?.data?.result?.status))) &&
            <>
              <div className={"w-full bg-text-400 h-0.5 mx-auto"} />
              <CancelBookingDetail newBookingDetail={newBookingDetail} />
            </>
          }
        </div>
      )}
      {(userBookingDetail?.message || tutorBookingDetail?.message) &&
        <span className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
          {userBookingDetail?.message || tutorBookingDetail?.message}
        </span>
      }
    </div>
  );
}

export default BookingSideOverContent;