
import ComponentLoader from "components/loader/ComponentLoader";
import RatingStars from "components/ratingStars/RatingStars";
import ReviewCard from "components/ReviewCard";

import { useAppState } from "hooks/useStore";

const TutorReviewList = () => {
    const { tutorReviewList } = useAppState((s) => s.review)

    return (
        <>
            {tutorReviewList?.isLoading &&
                <ComponentLoader isLoading={tutorReviewList?.isLoading} className={"w-full h-fit flex items-center justify-center"} />
            }
            {(!tutorReviewList?.isLoading && (tutorReviewList?.filteredTutorReviewList?.reviews?.length > 0)) &&
                <div className={"flex flex-col items-center justify-center md:items-start md:justify-end gap-3 p-5 bg-white rounded-lg"}>
                    <div className="flex items-center justify-start">
                        <span className='font-bodyPri font-medium text-lg tracking-wide text-text-900'>
                            {"Reviews"}
                        </span>
                        {tutorReviewList?.filteredTutorReviewList?.rating?.avgRating && <RatingStars rating={tutorReviewList?.filteredTutorReviewList?.rating?.avgRating?.toFixed(1)} fullRating={5} />}
                        <span className="font-medium text-md">
                            {tutorReviewList?.filteredTutorReviewList?.avgRating?.toFixed(1)} ({tutorReviewList?.filteredTutorReviewList?.rating?.totalStars?.count})
                        </span>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'>
                        {tutorReviewList?.filteredTutorReviewList?.reviews?.map((ele, idx) => (
                            <div className='col-span-1'>
                                <ReviewCard
                                    key={idx}
                                    rating={ele?.rating}
                                    content={ele?.review}
                                    profileImg={ele?.provider?.profilePicUrl}
                                    fName={ele?.provider?.firstName}
                                    lName={ele?.provider?.lastName}
                                    createdAt={ele?.createdAt}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            }
            {((tutorReviewList?.filteredTutorReviewList?.reviews?.length === 0) || tutorReviewList?.errorMsg) &&
                <div className='w-full bg-white p-5 rounded-lg flex flex-col items-start justify-start gap-5'>
                    <span className='font-bodyPri font-medium text-lg tracking-wide text-text-900'>
                        {"Reviews"}
                    </span>
                    <span className={"text-base font-bodyPri font-normal tracking-wide"}>
                        {tutorReviewList?.errorMsg || "No reviews Found!"}
                    </span>
                </div>
            }
        </>
    )
}

export default TutorReviewList;