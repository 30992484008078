import { useEffect, useState } from 'react';
import { pageHeading } from './data';
import PageHeader from 'components/pageHeader/PageHeader';
import GoogleCalendar from './intergrationProvider/googleCalendar';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { pagesInfo } from 'utils/pagesInfo';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { useTitle } from 'hooks/useTitle';


const Integrations = () => {
    const { user } = useAppState(s => s.user);
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const [title, setTitle] = useTitle()
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.STUDENT_CERTIFICATES))
    }, [dispatcher])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Intergrations | Edulyte`
            })
        }
    }, [user?.user])


    return (
        <div className="h-full w-full p-3 mx-auto min-h-screen space-y-5 bg-background-light font-bodyPri">
            <PageHeader pageHeading={pageHeading} />
            <div className='bg-white p-5 rounded-lg space-y-8'>
                <h2 className='font-medium'>Edulyte integrates with various tools to streamline your workflow and boost productivity.</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5'>
                    <GoogleCalendar />
                </div>
            </div>
        </div>
    )
}

export default Integrations;
