import {
    dayjs,
    generateUtcWeekDays,
    generateLocalWeekDays,
    getTimeZoneOffset,
    timeZone as localTimeZone
} from 'utils/dateTime.utils';
import { weeklyTimeSlotObject } from 'redux/local/local.const';

function sortDate(item1, item2) {
    let date1 = dayjs(item1.date + "+00:00", "YYYY-MM-DDZ")
    let date2 = dayjs(item2.date + "+00:00", "YYYY-MM-DDZ")
    return date1.isSameOrAfter(date2) ? 1 : -1
}

function sortSlot(slot1, slot2) {
    let toDateUtc = dayjs().utc().format('YYYY-MM-DD').toString()
    let slot1StartTime = dayjs(toDateUtc + " " + slot1.startTime + "+00:00", "YYYY-MM-DD HH:mm:ssZ")
    let slot2StartTime = dayjs(toDateUtc + " " + slot2.startTime + "+00:00", "YYYY-MM-DD HH:mm:ssZ")
    return slot1StartTime.isSameOrAfter(slot2StartTime) ? 1 : -1
}

export const getUtcToLocalWeeklyAvailability = async ({ timeZone = localTimeZone, utcWeeklyAvail }) => {
    let daySlots = {}

    // Creating all week days with empty slot
    for (let day in utcWeeklyAvail) {
        daySlots[day] = []
    }

    // Slots conversion from utc to local timeZone 
    const utcWeekDays = generateUtcWeekDays()
    for (let day in utcWeeklyAvail) {
        utcWeeklyAvail[day].forEach(slot => {
            let localDay = dayjs(utcWeekDays[day.toUpperCase()].date + " " + slot.startTime + "+00:00", "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("dddd")
            let localStartTime = dayjs(utcWeekDays[day.toUpperCase()].date + " " + slot.startTime + "+00:00", "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("HH:mm:ss")
            let localEndTime = dayjs(utcWeekDays[day.toUpperCase()].date + " " + slot.endTime + "+00:00", "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("HH:mm:ss")
            daySlots[localDay].push({ startTime: localStartTime, endTime: localEndTime })
        })
    }

    // Sort all the slots contains in a day
    let sortedAvailability = {}
    for (let day in daySlots) {
        let slots = [...daySlots[day]]
        sortedAvailability[day] = slots.sort(sortSlot)
    }

    // Merge slot which slot has endTime is equal to next startTime
    let mergedAvailability = {}
    for (let day in sortedAvailability) {
        mergedAvailability[day] = []
        if (sortedAvailability[day].length <= 1) {
            continue;
        }
        let firstSlot = { ...sortedAvailability[day][0] }
        sortedAvailability[day].slice(1).forEach(slot => {
            if (firstSlot.endTime === slot.startTime) {
                firstSlot.endTime = slot.endTime
            } else {
                mergedAvailability[day].push(firstSlot)
                firstSlot = slot
            }
        })
        mergedAvailability[day].push(firstSlot)
    }

    return mergedAvailability
}

export const getLocalToUtcWeeklyAvailability = async ({ timeZone = localTimeZone, localWeeklyAvail }) => {
    let daySlots = {}

    // Creating all week days with empty slot
    for (let day in localWeeklyAvail) {
        daySlots[day] = []
    }

    let timeZoneOffset = getTimeZoneOffset(timeZone)
    let localWeekDays = generateLocalWeekDays(timeZone)

    // Break down all slots in a day in 15 minute interval And Slots conversion from local to utc timeZone 
    for (let localDay in localWeeklyAvail) {
        localWeeklyAvail[localDay].forEach(timeSlot => {
            let startDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            let endDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            if (timeSlot.endTime === '00:00:00') {
                endDayjs = endDayjs.add(1, "day")
            }
            while (startDayjs.isBefore(endDayjs) && startDayjs.add(15, "minute").isSameOrBefore(endDayjs)) {
                let utcDay = startDayjs.utc().format("dddd")
                const slot = {
                    startTime: startDayjs.utc().format("HH:mm:ss").toString(),
                    endTime: startDayjs.utc().add(15, "minute").format("HH:mm:ss").toString()
                }
                daySlots[utcDay].push(slot)
                startDayjs = startDayjs.add(15, "minute")
            }
        })
    }

    // Sort all the slots contains in a day
    let sortedAvailability = {}
    for (let day in daySlots) {
        let slots = [...daySlots[day]]
        sortedAvailability[day] = slots.sort(sortSlot)
    }

    return sortedAvailability
}

export const getUtcToLocalOffDays = async ({ timeZone = localTimeZone, utcOffDays }) => {
    let offDays = []

    // OffDay conversion from utc to local timeZone 
    utcOffDays.forEach(offDay => {
        offDay?.timeSlots?.forEach(timeSlot => {
            let localDate = dayjs(offDay?.date + " " + timeSlot.startTime + "+00:00", "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("YYYY-MM-DD")
            let localStartTime = dayjs(offDay?.date + " " + timeSlot.startTime + "+00:00", "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("HH:mm:ss")
            let localEndTime = dayjs(offDay?.date + " " + timeSlot.endTime + "+00:00", "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("HH:mm:ss")

            let localOffDay = offDays?.find(offDay => offDay?.date === localDate)
            if (!localOffDay) {
                localOffDay = {
                    name: offDay?.name,
                    date: localDate,
                    timeSlots: []
                }
                offDays.push(localOffDay)
            }
            localOffDay.timeSlots.push({ startTime: localStartTime, endTime: localEndTime })
        })

    })

    // Sort all the slots contains in a offDay
    let sortedOffDays = []
    sortedOffDays = offDays.reverse(sortDate).map(offDay => {
        return {
            ...offDay,
            timeSlots: offDay.timeSlots.sort(sortSlot)
        }
    })

    // Merge slot which slot has endTime is equal to next startTime
    let mergedOffDayTimeSlots = []
    mergedOffDayTimeSlots = sortedOffDays.map(offDay => {
        if (offDay.timeSlots.length < 1) {
            return offDay
        }
        let mergeTimeSlots = []
        let firstSlot = offDay.timeSlots[0]
        offDay.timeSlots.slice(1).forEach(slot => {
            if (firstSlot.endTime === slot.startTime) {
                firstSlot.endTime = slot.endTime
            } else {
                mergeTimeSlots.push(firstSlot)
                firstSlot = slot
            }
        })
        mergeTimeSlots.push(firstSlot)
        return {
            ...offDay,
            timeSlots: mergeTimeSlots
        }
    })

    return mergedOffDayTimeSlots
}

export const getLocalToUtcOffDays = async ({ timeZone = localTimeZone, localOffDays }) => {
    let utcOffDays = []

    let timeZoneOffset = getTimeZoneOffset(timeZone)

    // Break down all slots in a offDay in 15 minute interval And Slots conversion from local to utc timeZone
    localOffDays?.forEach(offDay => {
        offDay?.timeSlots?.forEach(timeSlot => {
            let startDayjs = dayjs(offDay.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            let endDayjs = dayjs(offDay.date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            if (timeSlot.endTime === '00:00:00') {
                endDayjs = endDayjs.add(1, "day")
            }
            while (startDayjs.isBefore(endDayjs) && startDayjs.add(15, "minute").isSameOrBefore(endDayjs)) {
                let utcDate = startDayjs.utc().format("YYYY-MM-DD")
                const slot = {
                    startTime: startDayjs.utc().format("HH:mm:ss").toString(),
                    endTime: startDayjs.utc().add(15, "minute").format("HH:mm:ss").toString()
                }
                startDayjs = startDayjs.add(15, "minute")

                let utcOffDay = utcOffDays?.find(utcOffDay => utcOffDay?.date === utcDate)
                if (!utcOffDay) {
                    utcOffDay = {
                        name: offDay.name.trim(),
                        date: utcDate.toString(),
                        timeSlots: []
                    }
                    utcOffDays.push(utcOffDay)
                }
                utcOffDay.timeSlots.push(slot)
            }
        })
    })

    // Sort all the slots contains in a offDay
    let sortedUtcOffDays = []
    sortedUtcOffDays = utcOffDays.sort(sortDate).map(offDay => {
        return {
            ...offDay,
            timeSlots: offDay.timeSlots.sort(sortSlot)
        }
    })

    return sortedUtcOffDays
}

export const validateSessionDuration = ({ timeZone = localTimeZone, localDay, timeSlot, sessionDuration = 30 }) => {
    const localWeekDays = generateLocalWeekDays(timeZone)
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let selectedDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + "00:00:00" + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let startDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let endDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)

    if (startDayjs.isBefore(selectedDayjs) && endDayjs.isAfter(selectedDayjs)) {
        return true
    }

    if (timeSlot?.endTime === "00:00:00") {
        endDayjs = endDayjs.add(1, "day")
    }

    let startEndDayjsDiff = endDayjs?.diff(startDayjs, "minutes")

    if (startEndDayjsDiff <= sessionDuration) {
        return false
    } else {
        return true
    }
}

export const getTimeSlotWithDuration = ({ timeZone = localTimeZone, localDay, timeSlot, sessionDuration = 30, totalTimeSlots = 1 }) => {
    const localWeekDays = generateLocalWeekDays(timeZone)
    const timeZoneOffset = getTimeZoneOffset(timeZone)
    let startDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let endDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    
    if (timeSlot.endTime === "00:00:00") {
        endDayjs = endDayjs.add(1, "day")
    }

    let startTime, endTime;

    if (totalTimeSlots === 0) {
        startTime = startDayjs;
        endTime = startDayjs.add(sessionDuration, "minute");
    } else {
        startTime = endDayjs;
        endTime = endDayjs.add(sessionDuration, "minute");
    }

    const slot = {
        startTime: startTime.format("HH:mm:ss"),
        endTime: endTime.format("HH:mm:ss")
    }

    return slot;
}

export const getGroupTimeSlotWithDuration = ({ timeZone = localTimeZone, localDay, timeSlot, sessionDuration = 30, totalTimeSlots = 1 }) => {
    const localWeekDays = generateLocalWeekDays(timeZone)
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let startDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)

    let endDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    if (timeSlot.endTime === "00:00:00") {
        endDayjs = endDayjs.add(1, "day")
    }

    endDayjs = startDayjs.add(sessionDuration, "minute").format("HH:mm:ss").toString()
    startDayjs = startDayjs.format("HH:mm:ss").toString()

    const slot = {
        startTime: startDayjs,
        endTime: endDayjs
    }

    return slot;
}

export const getWeeklyAvailTimeSlotsWithDuration = ({ timeZone = localTimeZone, weekdays, weeklyAvail, sessionDuration = 30 }) => {
    const localWeekDays = generateLocalWeekDays(timeZone)
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let weeklyAvailTimeSlots = {}
    for (let weekday in weekdays) {
        const sortDay = weekdays[weekday]?.slice(0, 3)?.toUpperCase()
        const day = weekdays[weekday]
        const timeSlotArray = weeklyAvail[day?.slice(0, 3)?.toUpperCase()]

        let weeklyAvailTimeSlot = timeSlotArray?.map((slot) => {
            let startDayjs = dayjs(localWeekDays[day?.toUpperCase()].date + "" + slot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            let endDayjs = dayjs(localWeekDays[day?.toUpperCase()].date + "" + slot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            if (slot.endTime === "00:00:00") {
                endDayjs = endDayjs.add(1, "day")
            }
            endDayjs = startDayjs.add(sessionDuration, "minute").format("HH:mm:ss").toString()
            startDayjs = startDayjs.format("HH:mm:ss").toString()

            const startEndTimeSlot = {
                startTime: startDayjs,
                endTime: endDayjs
            }

            return startEndTimeSlot;
        })
        weeklyAvailTimeSlots[sortDay] = weeklyAvailTimeSlot
    }
    return weeklyAvailTimeSlots
}

export const getDateTimeSlotsWithDuration = async (timeZone, dateTimeAvailabilities, duration) => {
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    const dateTimeSlotDuration = []
    dateTimeAvailabilities?.forEach(dailyAvailability => {
        const timeSlots = []
        dailyAvailability.timeSlots.forEach(timeSlot => {
            let startDayjs = dayjs(dailyAvailability.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            let endDayjs = dayjs(dailyAvailability.date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            if (timeSlot.endTime === "00:00:00") {
                endDayjs = endDayjs.add(1, "day")
            }
            const slot = {
                startTime: startDayjs.format("HH:mm:ss").toString(),
                endTime: startDayjs.add(duration, "minute").format("HH:mm:ss").toString()
            }
            timeSlots.push(slot)

        })
        if (timeSlots.length > 0) {
            dateTimeSlotDuration.push({
                date: dailyAvailability.date,
                timeSlots: timeSlots
            })
        }
    })
    return dateTimeSlotDuration
}

export const validateEndTimeSessionDuration = async ({ timeZone = localTimeZone, localDay, timeSlot, sessionDuration = 30 }) => {
    const localWeekDays = generateLocalWeekDays(timeZone)
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let startDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let endDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)

    if (timeSlot.endTime === "00:00:00") {
        endDayjs = endDayjs.add(1, "day")
    }
    const slot = {
        startTime: startDayjs.format("HH:mm:ss").toString(),
        endTime: startDayjs.add(sessionDuration, "minute").format("HH:mm:ss").toString()
    }
    return slot;

}

export const validateIsTimeSlotValid = ({ timeZone = localTimeZone, localDay, selectedTimeSlot }) => {
    const localWeekDays = generateLocalWeekDays(timeZone)
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let defaultDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + "00:00:00" + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let startDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + selectedTimeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let endDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + selectedTimeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)

    if (startDayjs.isBefore(defaultDayjs.add(1, 'day')) && endDayjs.isAfter(defaultDayjs)) {
        if ((startDayjs.hour() === 23) && (startDayjs.minute() >= 30)) {
            endDayjs = defaultDayjs
        }
    }
    return { startTime: startDayjs.format("HH:mm:ss").toString(), endTime: endDayjs.format("HH:mm:ss").toString() }
}

export const validateIsStartBeforeEnd = ({ timeZone = localTimeZone, localDay, selectedTimeSlot }) => {
    const localWeekDays = generateLocalWeekDays(timeZone)
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let startDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + selectedTimeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let endDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + selectedTimeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    if (selectedTimeSlot.endTime === "00:00:00") {
        endDayjs = endDayjs.add(1, "day")
    }
    return startDayjs.isBefore(endDayjs)
}

export const validateIsTimeSlotExist = ({ timeZone = localTimeZone, localDay, selectedTimeSlot, timeSlots }) => {
    const localWeekDays = generateLocalWeekDays(timeZone)
    const timeZoneOffset = getTimeZoneOffset(timeZone)
    let selectStartDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + selectedTimeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let selectEndDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + selectedTimeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    if (selectedTimeSlot.endTime === "00:00:00") {
        selectEndDayjs = selectEndDayjs.add(1, "day")
    }
    timeSlots = timeSlots?.filter(timeSlot => {
        let startDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
        let endDayjs = dayjs(localWeekDays[localDay.toUpperCase()].date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
        if (timeSlot.endTime === "00:00:00") {
            endDayjs = endDayjs.add(1, "day")
        }
        if ((selectStartDayjs.isSameOrBefore(startDayjs) && selectEndDayjs.isSameOrBefore(startDayjs)) || (selectStartDayjs.isSameOrAfter(endDayjs) && selectEndDayjs.isSameOrAfter(endDayjs))) {
            return false
        } else {
            return true
        }
    })

    return timeSlots.length > 0 ? true : false
}

export const getIsTimeSlotBooked = ({ selectedDateTimeSlot, eventList }) => {
    eventList = eventList?.filter(event => event.eventType !== 'premium').filter(event => {
        if (['pending', 'scheduled', 'on_going', 'rescheduled'].includes(event.status)) return true
        return false
    }).filter(event => {
        const startDateTime = dayjs(event.startDateTime)
        const endDateTime = dayjs(event.endDateTime)
        if ((dayjs(selectedDateTimeSlot.start).isSameOrBefore(startDateTime) && dayjs(selectedDateTimeSlot.end).isSameOrBefore(startDateTime)) ||
            (dayjs(selectedDateTimeSlot.start).isSameOrAfter(endDateTime) && dayjs(selectedDateTimeSlot.end).isSameOrAfter(endDateTime))) {
            return false
        } else {
            return true
        }
    })
    return eventList?.length > 0 ? true : false
}

export const getIsAvailable = ({ availDateTimeSlot, eventList }) => {
    eventList = eventList?.filter(event => event.eventType !== 'premium').filter(event => {
        if (['pending', 'scheduled', 'on_going', 'rescheduled'].includes(event.status)) return true
        return false
    }).filter(event => {
        const startDateTime = dayjs(event.startDateTime)
        const endDateTime = dayjs(event.endDateTime)
        if ((dayjs(availDateTimeSlot.start).isSameOrBefore(startDateTime) && dayjs(availDateTimeSlot.end).isSameOrBefore(startDateTime)) ||
            (dayjs(availDateTimeSlot.start).isSameOrAfter(endDateTime) && dayjs(availDateTimeSlot.end).isSameOrAfter(endDateTime))) {
            return false
        } else {
            return true
        }
    })
    return eventList?.length > 0
        ? false
        : dayjs(availDateTimeSlot.start).isBefore(dayjs())
            ? false
            : true
}

export const getUtcToLocalAvailSlots = async ({ timeZone = localTimeZone, utcWeeklyAvail = weeklyTimeSlotObject, utcOffDays = [], duration = 15, slotPeriodDays = 14 }) => {
    let weeklyAvail = await getUtcToLocalWeeklyAvailability({ timeZone, utcWeeklyAvail })

    let dailyAvailabilities = []
    let today = dayjs().tz(timeZone)
    for (let index = 0; index < slotPeriodDays; index++) {
        const toDate = today.add(index, 'day')
        const dayName = toDate.format("dddd").toString()
        if (weeklyAvail[dayName]) {
            dailyAvailabilities.push({
                date: toDate.format('YYYY-MM-DD').toString(),
                timeSlots: weeklyAvail[dayName]
            })
        }
    }

    const dailyAvailabilitiesWithDuration = await getDailyAvailabilitiesWithDuration(timeZone, dailyAvailabilities, duration)
    const dailyAvailabilitiesExcludeOffDays = await getDailyAvailabilitiesExcludeOffDays(timeZone, dailyAvailabilitiesWithDuration, utcOffDays)

    return dailyAvailabilitiesExcludeOffDays
}

const getDailyAvailabilitiesWithDuration = async (timeZone, dailyAvailabilities, duration) => {
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    const dailyAvailabilitiesWithDuration = []
    dailyAvailabilities?.forEach(dailyAvailability => {
        const timeSlots = []
        dailyAvailability.timeSlots.forEach(timeSlot => {
            let startDayjs = dayjs(dailyAvailability.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            let endDayjs = dayjs(dailyAvailability.date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            if (timeSlot.endTime === "00:00:00") {
                endDayjs = endDayjs.add(1, "day")
            }
            while (startDayjs.isBefore(endDayjs) && startDayjs.add(duration, "minute").isSameOrBefore(endDayjs)) {
                const slot = {
                    startTime: startDayjs.format("HH:mm:ss").toString(),
                    endTime: startDayjs.add(duration, "minute").format("HH:mm:ss").toString()
                }
                timeSlots.push(slot)
                startDayjs = startDayjs.add(duration, "minute")
            }
        })
        if (timeSlots.length > 0) {
            dailyAvailabilitiesWithDuration.push({
                date: dailyAvailability.date,
                timeSlots: timeSlots
            })
        }
    })
    return dailyAvailabilitiesWithDuration
}

const getDailyAvailabilitiesExcludeOffDays = async (timeZone, dailyAvailabilities, utcOffDays) => {
    const timeZoneOffset = getTimeZoneOffset(timeZone)
    const localOffDays = await getUtcToLocalOffDays({ timeZone, utcOffDays })

    const dailyAvailabilitiesExcludeOffDays = dailyAvailabilities.filter(dailyAvail => {
        dailyAvail.timeSlots = dailyAvail.timeSlots.filter(timeSlot => {
            let availDateTime = {
                start: dayjs(dailyAvail.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone),
                end: dayjs(dailyAvail.date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            }
            if (timeSlot.endTime === "00:00:00") {
                availDateTime.end = availDateTime.end.add(1, "day")
            }
            return getIsAvailabilityOff({ timeZone, availDateTime, localOffDays })
        })
        return dailyAvail.timeSlots.length > 0 ? true : false
    })
    return dailyAvailabilitiesExcludeOffDays
}

const getIsAvailabilityOff = ({ timeZone, availDateTime, localOffDays }) => {
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let count = 0
    localOffDays?.forEach(offDay => {
        offDay?.timeSlots.forEach(timeSlot => {
            let startDateTime = dayjs(offDay?.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            let endDateTime = dayjs(offDay?.date + " " + timeSlot.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
            if (timeSlot.endTime === "00:00:00") {
                endDateTime = endDateTime.add(1, "day")
            }
            if ((availDateTime.start.isSameOrBefore(startDateTime) && availDateTime.end.isSameOrBefore(startDateTime)) ||
                (availDateTime.start.isSameOrAfter(endDateTime) && availDateTime.end.isSameOrAfter(endDateTime))) {
            } else {
                count++
            }
        })
    })
    return count > 0 ? false : true
}

export const mergeUtcCourseSlots = async ({ utcCourseSlots }) => {
    // Sort all the slots contains in a day
    let sortedAvailability = {}
    for (let day in utcCourseSlots) {
        let slots = [...utcCourseSlots[day]]
        sortedAvailability[day] = slots.sort(sortSlot)
    }

    // Merge slot which slot has endTime is equal to next startTime
    let mergedAvailability = {}
    for (let day in sortedAvailability) {
        mergedAvailability[day] = []
        if (sortedAvailability[day].length <= 1) {
            continue;
        }
        let firstSlot = { ...sortedAvailability[day][0] }
        sortedAvailability[day].slice(1).forEach(slot => {
            if (firstSlot.endTime === slot.startTime) {
                firstSlot.endTime = slot.endTime
            } else {
                mergedAvailability[day].push(firstSlot)
                firstSlot = slot
            }
        })
        mergedAvailability[day].push(firstSlot)
    }

    return mergedAvailability
}