import { userRoles } from "redux/auth/auth.const"

export const pageHeading = {
    heading: "Comprehensive Website Tour: Your Video Library Guide",
    subHeading: "Our team is here to help you succeed, there are many ways to get support!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/support.svg"
}

export const getVideoData = (pagesInfo, userRole) => {
    return {
        [pagesInfo.LMS_QUIZ_PAGE.pageName]: {
            key: pagesInfo.LMS_QUIZ_PAGE.pageName,
            title: "How to Create an Engaging Quiz: Step-by-Step Tutorial",
            videoId: "8YxmhhqVgOY",
            roles: [userRoles.TUTOR.value],
        },
        [pagesInfo.DASHBOARD.pageName]: {
            key: pagesInfo.DASHBOARD.pageName,
            title: "Ultimate Guide to Navigating the Tutor Dashboard",
            videoId: userRole === userRoles.TUTOR.value ? "efkTw7JYH5k" : "JznOn5NLt4k",
            roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
        },
        [pagesInfo.APPOINTMENT_LIST.pageName]: {
            key: pagesInfo.APPOINTMENT_LIST.pageName,
            title: "How to Create and Manage Appointment : A Step-by-Step Guide",
            videoId: "D4NtWbNMzMw",
            roles: [userRoles.TUTOR.value],
        },
        [pagesInfo.DOWNLOADS.pageName]: {
            key: pagesInfo.DOWNLOADS.pageName,
            title: "How to Upload and Sell Digital Downloadables Online",
            videoId: "ENBNTMmQzVM",
            roles: [userRoles.TUTOR.value],
        },
        [pagesInfo.LMS_QUESTIONS_PAGE.pageName]: {
            key: pagesInfo.LMS_QUESTIONS_PAGE.pageName,
            title: "How to Create Questions for Quizzes: A Step-by-Step Tutorial",
            videoId: "tSPajunpt-Q",
            roles: [userRoles.TUTOR.value],
        },
    }
}
