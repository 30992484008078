import { useState, memo, useMemo } from "react";

import { sortTimeSlots, actionButtons } from "components/availabilitySchedule/data";
import ActionIconButtons from "components/availabilitySchedule/ActionIconButtons";
import TimeRange from 'components/availabilitySchedule/TimeRange';

import { cn } from "utils/cn.utils";
import { getTimeSlotWithDuration, validateIsStartBeforeEnd, validateIsTimeSlotExist, validateIsTimeSlotValid } from 'utils/availability.utils';
import { dayjs, timeZone } from 'utils/dateTime.utils';

const RecurringSchedule = memo(({
    isLoading = false,
    isDisabled = false,
    selectedTimeZone = timeZone,
    localDay,
    weeklyAvailability,
    saveWeeklyAvail
}) => {

    const [selectedTimeSlot] = useState({
        startTime: dayjs().tz(selectedTimeZone).format('09:[00:00]'),
        endTime: dayjs().tz(selectedTimeZone).format('09:[30:00]')
    });

    const iconButtonList = useMemo(() => {
        let buttons = Object.values(actionButtons)?.filter((iconBtn) => iconBtn?.value !== actionButtons.DELETE.value)
        if (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length === 0) {
            buttons = Object.values(buttons)?.filter((iconButton => iconButton?.value !== actionButtons.APPLY_TO_ALL.value))
        }

        return buttons;
    }, [weeklyAvailability, localDay])

    const onHandleAddSlot = async () => {
        if (isLoading || isDisabled) return;

        let newSelectedTimeSlot = selectedTimeSlot
        if (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0) {
            let startEndTimeSlot = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlots)?.slice(-1)[0]
            const totalTimeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length
            newSelectedTimeSlot = getTimeSlotWithDuration({ selectedTimeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: 30, totalTimeSlots })
        }

        if (!validateIsStartBeforeEnd({ selectedTimeZone, localDay, selectedTimeSlot: newSelectedTimeSlot })) {
            alert("End time should be after the start time!")
            return;
        }

        const timeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]
        if (validateIsTimeSlotExist({ selectedTimeZone, localDay, selectedTimeSlot: newSelectedTimeSlot, timeSlots })) {
            alert("Slot is already exist!")
            return;
        }

        let newAvailSlots = { ...weeklyAvailability, [localDay?.slice(0, 3)?.toUpperCase()]: [...timeSlots, newSelectedTimeSlot] }

        await saveWeeklyAvail(newAvailSlots)
    }

    const onHandleUpdateSlot = async (oldTimeSlot, updatedTimeSlot) => {
        if (isLoading || isDisabled) return;

        let newTimeSlot = updatedTimeSlot
        
        if (!validateIsStartBeforeEnd({ selectedTimeZone, localDay, selectedTimeSlot: newTimeSlot })) {
            if (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 1) {
                let startEndTimeSlot = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlots)?.slice(-1)[0]
                const totalTimeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length
                newTimeSlot = getTimeSlotWithDuration({ selectedTimeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: 30, totalTimeSlots })
            } else {
                newTimeSlot = getTimeSlotWithDuration({ selectedTimeZone, localDay, timeSlot: newTimeSlot, sessionDuration: 30, totalTimeSlots: 0 })
            }
            newTimeSlot = validateIsTimeSlotValid({ selectedTimeZone, localDay, selectedTimeSlot: newTimeSlot })
            // alert("End time should be after the start time!")
            // return;
        }

        let timeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()].filter(slot => oldTimeSlot.startTime !== slot.startTime)
        if (validateIsTimeSlotExist({ selectedTimeZone, localDay, selectedTimeSlot: newTimeSlot, timeSlots })) {
            alert("Slot already exist!")
            return;
        }

        timeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlots)?.map(slot => (
            oldTimeSlot.startTime === slot.startTime && oldTimeSlot.endTime === slot.endTime ? newTimeSlot : slot
        ))

        let newAvailSlots = { ...weeklyAvailability, [localDay?.slice(0, 3)?.toUpperCase()]: [...timeSlots] }

        await saveWeeklyAvail(newAvailSlots)
    }

    const onHandleDeleteSlot = async (selectedSlot) => {
        if (isLoading || isDisabled) return;

        const timeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()].slice()?.sort(sortTimeSlots)?.filter(slot => slot.startTime !== selectedSlot.startTime)

        let newAvailSlots = { ...weeklyAvailability, [localDay?.slice(0, 3)?.toUpperCase()]: [...timeSlots] }

        await saveWeeklyAvail(newAvailSlots)
    }

    const handleOnApplyAll = async () => {
        if (isLoading || isDisabled) return;

        let newAvailSlots = {
            ...weeklyAvailability,
            MON: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            TUE: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            WED: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            THU: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            FRI: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            SAT: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            SUN: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
        }

        await saveWeeklyAvail(newAvailSlots)
    }

    const onHandleIconButton = (selectedBtn) => {
        if (isLoading || isDisabled) return;
        if (selectedBtn.value === actionButtons.ADD.value) {
            onHandleAddSlot()
        } else if (selectedBtn.value === actionButtons.APPLY_TO_ALL.value) {
            handleOnApplyAll()
        }
    }

    return (
        <div className={"grid grid-cols-4 w-full gap-3 sm:gap-5"}>

            <div className={cn(
                "pt-1.5",
                (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0) && "col-start-1 col-span-full sm:col-start-1 sm:col-span-1",
                (!weeklyAvailability || (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length < 1)) && "col-start-1 col-span-1"
            )}>
                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                    {localDay}
                </span>
            </div>

            {(weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0) &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 space-y-3 w-full"}>
                    {weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlots)?.map((timeSlot, index) => (
                        <TimeRange
                            key={index}
                            isLoading={isLoading}
                            isDisabled={isDisabled}
                            selectedTimeZone={selectedTimeZone}
                            localDay={localDay}
                            timeSlot={timeSlot}
                            updateTimeSlot={onHandleUpdateSlot}
                            deleteTimeSlot={onHandleDeleteSlot}
                        />
                    ))}
                </div>
            }

            {(!weeklyAvailability || (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length < 1)) &&
                <div className={"col-start-2 col-span-2 pt-1.5 flex items-center justify-center"}>
                    <div className={"w-full font-bodyPri font-bold text-base text-text-900 tracking-wide text-left"}>
                        {"-"}
                    </div>
                </div>
            }

            <div className={"col-start-4 col-span-1 justify-self-end sm:col-start-4 sm:col-span-1 sm:justify-self-start"}>
                <div className={"w-full flex flex-row items-start justify-start space-x-2"}>
                    <ActionIconButtons
                        isLoading={isLoading}
                        isDisabled={isDisabled}
                        iconButtonList={Object.values(iconButtonList)}
                        onHandleIconButton={onHandleIconButton}
                    />
                </div>
            </div>
        </div>
    )
});

export default RecurringSchedule;