import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { useTitle } from 'hooks/useTitle'
import { useEffect, useState } from 'react'
import { setPageInfo } from 'redux/pageInfo/pageInfo.request'
import { pagesInfo } from 'utils/pagesInfo'
import SideHeader from './components/sideHeader'
import Preview from './components/preview'
import { useNavigate, useParams } from 'react-router-dom'
import certificateService from 'redux/certificate/certificate.service'
import FullPageLoader from 'components/loader/FullPageLoader'

const initialState = { isLoading: false, result: null, error: null }

const EditCertificatePage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const [certificateDetails, setCertificateDetails] = useState(initialState)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()
    const navigate = useNavigate()

    const { certificateId } = useParams()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_CERTIFICATE))
    }, [dispatcher])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Edit Certificate | Edulyte`
            })
        }
    }, [user?.user])

    useEffect(() => {
        handleGetCertificateDetails()
    }, [certificateId,])

    const handleGetCertificateDetails = async () => {
        setCertificateDetails({ ...initialState, isLoading: true });
        try {
            const requestData = {
                params: {
                    certificateId: certificateId,
                    userId: user?.user?.userId,
                },
            };
            const response = await certificateService.getCertificateDetails(requestData);
            if (response.status === 200) {
                setCertificateDetails((s) => ({ ...s, result: response.data.data.result }));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            setCertificateDetails((s) => ({
                ...s, error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
            }));
        } finally {
            setCertificateDetails((s) => ({ ...s, isLoading: false }))
        }
    };

    if (certificateDetails?.isLoading) {
        return (
            <FullPageLoader isLoading={certificateDetails?.isLoading} />
        )
    }
    if (certificateDetails?.error) {
        return (
            <div className='flex justify-center items-center flex-1 w-full h-full text-secondary-dark text-xl font-medium'>{certificateDetails?.error}</div>
        )
    }

    return (
        <div className='bg-white flex flex-1'>
            <div className='max-w-screen-2xl w-full flex flex-col lg:flex-row mx-auto'>
                <div className='w-full lg:w-1/3 shadow-all'>
                    <SideHeader certificate={certificateDetails?.result} setCertificateDetails={setCertificateDetails} />
                </div>
                <div className='w-full lg:w-4/6 hidden sm:flex justify-center items-center p-5'>
                    <Preview certificate={certificateDetails?.result} />
                </div>
            </div>
        </div>
    )
}

export default EditCertificatePage
