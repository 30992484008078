import { useMemo } from 'react'
import FloatingLabelSelect from 'components/floating/floatingLabelSelect'
import { certificateStatusEnum, selectCategory } from '../../data'
import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { getLmsCourseList } from 'redux/edulyteLms/lmsCourse/lmsCourse.request'
import { getLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request'
import { getTutorCourseList } from 'redux/course/course.request'
import { cn } from 'utils/cn.utils'

const SelectCategory = ({ certificate, setCertificateDetails }) => {
    const { user } = useAppState((state) => state.user)
    const { lmsCourseList } = useAppState((state) => state.lms.lmsCourse)
    const { lmsQuizList } = useAppState((state) => state.lms.lmsQuiz)
    const { tutorCourseList } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const handleSelectCategory = (select) => {
        setCertificateDetails(s => ({
            ...s,
            result: {
                ...s?.result,
                type: select?.value
            }
        }))
        if (select?.value === selectCategory?.lms_course?.value) {
            dispatcher(getLmsCourseList())
        }
        if (select?.value === selectCategory?.quiz?.value) {
            dispatcher(getLmsQuizList())
        }
        if (select?.value === selectCategory?.live_class?.value) {
            dispatcher(getTutorCourseList(user?.user?.tutor?.tutorId))
        }
    }

    const selectedProduct = (select) => {
        setCertificateDetails(s => ({
            ...s,
            result: {
                ...s?.result,
                placeholder: {
                    ...s?.result?.placeholder,
                    title: select?.label,
                },
                [s?.result?.type]: {
                    [`${s?.result?.type}_id`]: select?.key
                }
            }
        }))
    }

    const productList = useMemo(() => {
        if (certificate?.type === selectCategory?.lms_course?.value) {
            return lmsCourseList?.data?.results?.map(item => ({ key: item?.id, label: item?.title, thumbnail: item?.thumbnail_pic_url })) || [];
        }
        if (certificate?.type === selectCategory?.quiz?.value) {
            return lmsQuizList?.data?.results?.map(item => ({ key: item?.id, label: item?.title, thumbnail: item?.thumbnail_pic_url })) || [];
        }
        if (certificate?.type === selectCategory?.live_class?.value) {
            return tutorCourseList?.data?.results?.map(item => ({ key: item?.id, label: item?.title, thumbnail: item?.thumbnailPicUrl })) || [];
        }
        return [];
    }, [certificate?.type, lmsCourseList, lmsQuizList, tutorCourseList]);


    return (
        <div className='flex flex-col space-y-2 w-full'>
            <label className='px-2 text-text-600'>Category</label>
            <FloatingLabelSelect
                labelItem={"Category"}
                showLabel={false}
                isDisabled={certificate?.status === certificateStatusEnum.issued.value}
                isLoading={lmsCourseList?.isLoading || lmsQuizList?.isLoading || tutorCourseList?.isLoading}
                options={Object.values(selectCategory)}
                onHandleSelect={(e) => handleSelectCategory(e)}
                value={certificate?.type ? <p>{selectCategory[certificate?.type]?.label}</p> : <p>Select any</p>}
                OptionChild={({ option }) => (
                    <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                        {option?.label}
                    </div>
                )}
                customBtnStyle={cn("border-text-300", certificate?.status === certificateStatusEnum.issued.value && 'hover:border-text-200 outline-none focus-within:ring-1 bg-background-light text-text-300 focus-within:ring-text-200')}
                dropDownContainerClassName={"w-full"}
            />
            {certificate?.type &&
                <FloatingLabelSelect
                    labelItem={"products"}
                    showLabel={false}
                    searchable={true}
                    options={productList}
                    isDisabled={certificate?.status === certificateStatusEnum.issued.value || productList.length === 0}
                    onHandleSelect={(e) => selectedProduct(e)}
                    value={<p className="whitespace-nowrap line-clamp-1">{certificate?.placeholder?.title}</p>}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-4 py-2 flex gap-3 items-center">
                            <img
                                className='h-8 w-12 rounded object-cover'
                                src={option?.thumbnail || 'https://www.edulyte.com/wp-content/uploads/2023/07/Online-Teaching-Rules-1536x560.jpg'}
                            />
                            <span className='w-full line-clamp-1'>{option?.label}</span>
                        </div>
                    )}
                    customBtnStyle={cn("border-text-300", certificate?.status === certificateStatusEnum.issued.value && 'hover:border-text-200 outline-none focus-within:ring-1 bg-background-light text-text-300 focus-within:ring-text-200')}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                />
            }
        </div>
    )
}

export default SelectCategory
