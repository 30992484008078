import { useCallback, useMemo, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";

import ButtonLoader from 'components/loader/ButtonLoader';

import { createBookingDetail } from 'redux/booking/booking.request';

import { useConfirm } from 'hooks/useConfirm';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetBooking_Detail } from 'redux/booking/booking.slice';
import { bookingOfferingTypeEnum, bookingStatusEnum } from 'redux/booking/booking.const';

import { cn } from 'utils/cn.utils';
import { timeZone } from 'utils/dateTime.utils';
import { pagesInfo } from 'utils/pagesInfo';
import { locationPhoneCallTypeEnum, locationTypeEnum } from 'redux/appointment/appointment.const';

const RepeatBookingDetail = memo(({ newBookingDetail }) => {
    const { user } = useAppState((state) => state.user)
    const { booking_Detail, addBookingDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const { confirm, ConfirmDialog } = useConfirm();

    const isLoading = useMemo(() => addBookingDetail?.isLoading, [addBookingDetail?.isLoading])

    useEffect(() => {
        if (addBookingDetail?.message) {
            onHandleDialogBox()
        }
    }, [addBookingDetail?.message])

    useEffect(() => {
        if (booking_Detail?.data?.result) {
            let bookingId = booking_Detail?.data?.result?.id
            const bookingStatus = booking_Detail?.data?.result?.status
            dispatcher(resetBooking_Detail())
            if ([bookingStatusEnum.CONFIRMED.value]?.includes(bookingStatus)) {
                navigate(`${pagesInfo.USER_FINANCE.pagePath}${pagesInfo.BOOKINGS.pagePath}?actionBookingId=${bookingId}`)
            } else {
                navigate(`${pagesInfo?.CHECKOUT_BOOKING?.pagePath}/${bookingId}`)
            }
        }
    }, [booking_Detail?.data?.result])

    const onHandleDialogBox = async () => {
        dispatcher(resetBooking_Detail())
        await confirm("This booking is no longer available. It may have been removed or replaced.")
        navigate(`${pagesInfo.TUTOR_COURSE.pagePath}/${user?.user?.userId}/classes`)
    }

    const onHandleRepeatBooking = useCallback(() => {
        if (isLoading) return;

        let payload = {
            userId: user?.user?.userId,
            offeringType: newBookingDetail?.data?.result?.offeringType,
        }

        if (newBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) {
            const appointmentLocationPayload = {
                appointmentLocationId: Number(newBookingDetail?.data?.result?.bookingLocation?.reference_id)
            }
            if ([locationTypeEnum.phone_call.key]?.includes(newBookingDetail?.data?.result?.bookingLocation?.location_type)) {
                if ([locationPhoneCallTypeEnum.i_will_call.key]?.includes(newBookingDetail?.data?.result?.bookingLocation?.phone_call?.location_phone_call_type)) {
                    appointmentLocationPayload["phone_call"] = {
                        location_phone_call_type: newBookingDetail?.data?.result?.bookingLocation?.phone_call?.location_phone_call_type,
                        phone_number: `${newBookingDetail?.data?.result?.bookingLocation?.phone_call?.isd_code}-${newBookingDetail?.data?.result?.bookingLocation?.phone_call?.phone_number}`
                    }
                }
            } else if ([locationTypeEnum.invitee.key]?.includes(newBookingDetail?.data?.result?.bookingLocation?.location_type)) {
                appointmentLocationPayload["invitee"] = {
                    custom_text: newBookingDetail?.data?.result?.bookingLocation?.invitee?.custom_text
                }
            }
            payload = {
                ...payload,
                appointment: {
                    appointmentId: newBookingDetail?.data?.result?.appointment?.id,
                    timeZone: timeZone,
                    schedule: newBookingDetail?.data?.result?.bookingSchedule?.schedule || [],
                    appointment_location: appointmentLocationPayload
                }
            }
        } else if (newBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.COURSE.value) {
            payload = {
                ...payload,
                course: {
                    courseId: newBookingDetail?.data?.result?.course?.id,
                    timeZone: timeZone,
                }
            }
        }
        dispatcher(createBookingDetail(payload))

    }, [isLoading, user?.user, newBookingDetail?.data])

    return (
        <>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className={cn(
                    "py-1.5 px-3 md:px-5 flex items-center justify-center text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:opacity-90 rounded",
                    "font-bodyPri font-normal text-sm sm:text-base",
                    isLoading && "cursor-not-allowed"
                )}
                disabled={isLoading}
                onClick={onHandleRepeatBooking}
            >
                {isLoading &&
                    <ButtonLoader isLoading={true} />
                }
                {!isLoading &&
                    <span className={""}>
                        {"Repeat"}
                    </span>
                }
            </motion.button>
            <ConfirmDialog confirmBtnText={"Explore More Options"} title={'Oops'} />
        </>
    )
})

export default RepeatBookingDetail;