import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { createLinkInBio } from 'redux/linkTree/linkTree.request';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';

const CreateLinkHubModalContent = () => {
    const { createLinkTree } = useAppState((state) => state.linkTree);
    const { modal } = useAppState((s) => s.local);
    const [slug, setSlug] = useState('');
    const [error, setError] = useState('');

    const dispatcher = useAppDispatcher();

    console.log(createLinkTree)

    useEffect(() => {
        if (createLinkTree?.data?.result) {
            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_LINK_HUG_MODAL.key]: {
                    ...modal[modalConst.CREATE_LINK_HUG_MODAL.key],
                    isVisible: false,
                    title: "",
                }
            }))
        }
    }, [createLinkTree?.data?.result])

    const validateSlug = (value) => {
        const regex = /^[a-zA-Z0-9-_]+$/;
        if (value.length > 20) {
            return 'Slug cannot be more than 20 characters';
        }
        if (!regex.test(value)) {
            return 'Slug can only contain letters, numbers, hyphens, and underscores';
        }
        return '';
    };

    const handleCreateLinks = () => {
        const validationError = validateSlug(slug);
        if (validationError) {
            setError(validationError);
            return;
        }

        dispatcher(createLinkInBio({ slug }));
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSlug(value);
        setError(validateSlug(value));
    };

    return (
        <div className='flex flex-col justify-center items-center gap-5 p-10 font-bodyPri'>
            <input
                className={`h-12 w-full px-5 border rounded outline-primary-main ${error ? 'outline-secondary-dark' : ''}`}
                placeholder='Enter Unique Link Name'
                value={slug}
                onChange={handleInputChange}
            />
            {error && <p className='text-sm text-secondary-dark'>{error}</p>}
            {createLinkTree?.errorMsg &&
                <p className='text-sm text-secondary-dark'>{createLinkTree?.errorMsg}</p>
            }
            <motion.button
                whileHover={{ scale: 1.09 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleCreateLinks}
                disabled={createLinkTree?.isLoading || !!error}
                className='h-12 px-5 flex justify-center items-center bg-primary-main text-white rounded hover:bg-secondary-main font-medium'
            >
                {createLinkTree?.isLoading ? "Loading..." : "Create Now"}
            </motion.button>
        </div>
    );
};

export default CreateLinkHubModalContent;
