import { useState } from "react";
import authService from "redux/auth/auth.service";
import { useAppState } from "hooks/useStore";
import image from 'assets/image/google-calendar.png'
import { RotatingLines } from "react-loader-spinner";
import { cn } from "utils/cn.utils";
import { useNavigate } from "react-router-dom";
import { pagesInfo } from "utils/pagesInfo";

export const CalenderPage = () => {
  const { user } = useAppState(s => s.user);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate()

  const handleOpenCalendar = async () => {
    if (user?.user?.calendar?.google?.active) {
      navigate(pagesInfo?.INTEGRATIONS?.pagePath)
    } else {
      setLoading(true);
      setError('');
      try {
        const response = await authService.createGoogleCalendarIntegration()
        if (response.status === 200) {
          window.location.href = response?.data?.auth_url;
        } else {
          throw new Error(response)
        }
      } catch (error) {
        console.error("Google integration error:", error)
        setError("An error occurred while connecting to Google Calendar. Please try again.")
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div className="flex flex-col gap-3 items-center">
      <p className="text-lg font-medium text-center">Calendar Settings</p>
      <hr className="w-full" />
      <p className="text-lg text-center text-slate-500 mb-10">
        Connect Your Google Calendar To Edulyte Appointment Scheduling System
      </p>
      <img
        src={image}
        className="mb-5 w-16 aspect-square"
        alt="calendar"
      />
      <button
        className={cn("px-8 py-3 flex items-center gap-1 text-white rounded-full font-medium ease-in-out duration-200",
          user?.user?.calendar?.google?.active ? "bg-green-600" : "bg-primary-dark hover:bg-secondary-dark"
        )}
        onClick={handleOpenCalendar}
        disabled={loading || user?.isLoading}
      >
        <RotatingLines
          visible={loading || user?.isLoading}
          height="20"
          width="20"
          strokeWidth="5"
          animationDuration="0.75"
        />
        {(loading || user?.isLoading) && "Connecting..."}
        {(!loading && !user?.isLoading && user?.user?.calendar?.google?.active) && "Connected"}
        {(!loading && !user?.isLoading && !user?.user?.calendar?.google?.active) && "Connect"}
      </button>
      {error &&
        <p className="text-sm text-center text-red-500 mt-2">
          {error}
        </p>
      }
      <p className="text-sm text-center text-slate-500 mt-10">
        {"Google Calendar is free time management software offered by google"}
      </p>
    </div>
  );
};
