import { useEffect, useState } from 'react'
import { cn } from 'utils/cn.utils'
import { motion } from 'framer-motion'
import { MdCheck, MdOutlinePreview } from "react-icons/md";

import certificateService from 'redux/certificate/certificate.service'
import IssuerDetailsAuto from './issuerDetailsAuto'
import ImageViewer from 'components/modals/imageViewer';
import ToolTipView from 'components/tooltipView';

const ProductsCertificateAutoSettings = ({ selectedTemplate, setSelectedTemplate }) => {
    const [templates, setTemplates] = useState({ isLoading: false, data: null, error: null })
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        handleGetTemplate()
    }, [])

    const handleGetTemplate = async () => {
        setTemplates((s) => ({ ...s, isLoading: true }));
        try {
            const response = await certificateService.getCertificateTemplateList();
            if (response.status === 200) {
                setTemplates((s) => ({
                    ...s,
                    data: response.data.data,
                    isLoading: false,
                }));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            setTemplates((s) => ({
                ...s,
                error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
                isLoading: false,
            }));
        }
    };

    const handlePreview = (imageUrl) => {
        setPreviewImage(imageUrl);
    };

    return (
        <div className='flex flex-col gap-5'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10'>
                {templates?.isLoading && new Array(3).fill("")?.map((_, index) => (
                    <div key={index} className='flex w-60 flex-col items-center justify-center gap-3 bg-background-light rounded p-5'>
                        <div className='h-48 w-32 bg-slate-200 animate-pulse' />
                        <div className='h-6 w-full bg-slate-200 animate-pulse' />
                    </div>
                ))}
                {!templates?.isLoading && templates?.data?.result?.map((template, index) => (
                    <motion.div
                        key={index}
                        whileHover={{ scale: 1.09 }}
                        onClick={() => setSelectedTemplate(template)}
                        className={cn('relative flex flex-col justify-center items-center hover:shadow p-5 gap-3 rounded group cursor-pointer ease-in-out duration-300 group',
                            "bg-background-light border-2 border-background-light",
                            selectedTemplate?.id === template?.id && "border-green-500"
                        )}>
                        <img
                            className='h-48 aspect-auto object-contain'
                            src={template?.image_url}
                        />
                        <p className='text-background-black font-medium group-hover:text-primary-dark text-center'>{template?.title}</p>
                        <MdCheck className={cn('absolute -top-0 right-0 text-2xl text-green-500',
                            selectedTemplate?.id === template?.id ? "block" : "hidden"
                        )} />
                        <ToolTipView content='Preview'>
                            <div className='absolute left-3 top-3 bg-white p-3 rounded-full shadow-md opacity-0 group-hover:opacity-100 ease-in-out duration-150 hover:scale-105'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePreview(template?.image_url);
                                }}
                            >
                                <MdOutlinePreview className='text-2xl text-text-700' />
                            </div>
                        </ToolTipView>
                    </motion.div>
                ))}
            </div>
            <IssuerDetailsAuto />
            {previewImage && (
                <ImageViewer
                    imageUrl={previewImage}
                    altText="Certificate Template Preview"
                    onClose={() => setPreviewImage(null)}
                />
            )}
        </div>
    )
}

export default ProductsCertificateAutoSettings
