import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const ImageViewer = ({ imageUrl, altText = 'image', onClose }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (imageUrl) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [imageUrl]);

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            handleClose();
        }
    };

    const handleClose = () => {
        setIsOpen(false);
        onClose();
    };

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75" onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
            <motion.div
                onClick={(e) => e.stopPropagation()}
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                transition={{ duration: 0.3 }}
            >
                <img
                    src={imageUrl}
                    alt={altText}
                    className="max-w-full max-h-screen rounded-md"
                />
                <div
                    className="absolute top-0 right-0 m-4 text-white text-4xl font-bold cursor-pointer"
                    onClick={handleClose}
                >
                    &times;
                </div>
            </motion.div>
        </motion.div>
    );
};

export default ImageViewer;
