import MyTypography from "components/Typography"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import appointmentService from "redux/appointment/appointment.service"
import { pagesInfo } from "utils/pagesInfo"
import AppointmentCard, { AppointmentCardLoader } from "../appointments/components/appointmentCard"
import { useAppState } from "hooks/useStore"

const initialState = {
    isLoading: false,
    result: null,
    error: null
}

const Appointments = () => {
    const { tutorPublicProfile } = useAppState((s) => s.tutor)

    const [appointments, setAppointments] = useState(initialState)

    const getUserAppointments = async (requestData) => {
        setAppointments({ ...initialState, isLoading: true })
        try {
            const response = await appointmentService.getPublicUserAppointmentList(requestData)
            if (response.status === 200) {
                setAppointments(s => ({ ...s, result: response?.data?.data?.result, error: null }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.error || error)
            setAppointments(s => ({ ...s, result: null, error: error?.response?.data?.error || "Something went wrong!" }))
        } finally {
            setAppointments(s => ({ ...s, isLoading: false }))
        }
    }

    useEffect(() => {
        if (tutorPublicProfile?.tutorPublicProfile?.username) {
            const requestData = {
                params: { user_id: tutorPublicProfile?.tutorPublicProfile?.username },
                query: { page: 1, records: 2 }
            }
            getUserAppointments(requestData)
        }

    }, [tutorPublicProfile?.tutorPublicProfile?.username])

    return (
        <div className="p-2.5 md:p-6 space-y-5" id={"APPOINTMENTS"}>
            <div className="flex justify-between items-center">
                <MyTypography className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
                    {"Book a session"}
                </MyTypography>
                <Link
                    to={`${pagesInfo.TUTOR_COURSE.pagePath}/${tutorPublicProfile?.tutorPublicProfile?.userId}/classes`}
                    className="cursor-pointer text-sm text-primary-main font-bodyPri tracking-wide"
                >
                    {"View All"}
                </Link>
            </div>
            {appointments?.isLoading && new Array(4).fill("").map((_, index) => <AppointmentCardLoader key={index} />)}
            {appointments?.result?.map(appointment => <AppointmentCard key={appointment?.id} appointment={appointment} />)}
            {appointments?.error && (
                <div className="w-full h-28 flex items-center justify-center">
                    <span className={"font-bodyPri font-normal text-xl text-text-700"}>
                        {appointments?.error}
                    </span>
                </div>
            )}
        </div>
    )
}

export default Appointments