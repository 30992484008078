import { useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'

import { useLocation, useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn.utils'
import dayjs from 'dayjs'
import { certificateListHeader, certificateMenuItems, certificateStatusEnum, getCertificateListPayload, pageHeading, searchParamsInfo, selectCategory } from './data'
import PageHeader from 'components/pageHeader/PageHeader'
import LMSTable from '../edulyteLms/commonComponents/tableComponent/LMSTable'
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu'

import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { useTitle } from 'hooks/useTitle'
import { setPageInfo } from 'redux/pageInfo/pageInfo.request'
import { pagesInfo } from 'utils/pagesInfo'
import { timeZone } from 'utils/dateTime.utils'
import certificateService from 'redux/certificate/certificate.service'
import { setModal, setShareSocial } from 'redux/local/local.slice'
import { modalConst } from 'redux/local/local.const'
import Pagination from 'components/pagination/Pagination'
import { toast } from 'react-toastify';
import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import FilterIcon from 'components/pageFilter/FilterIcon'
import { useConfirm } from 'hooks/useConfirm'

const initialState = {
    isLoading: false,
    data: null,
    pagination: null,
    error: null,
}

const CreateCertificate = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { confirm, ConfirmDialog } = useConfirm();

    const [certificateData, setCertificateData] = useState(initialState)
    const [deleteCert, setDeleteCert] = useState({ isLoading: false, message: null })
    const [cloneCert, setCloneCert] = useState({ isLoading: false, message: null })

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()
    const navigate = useNavigate()
    const location = useLocation()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.CREATE_CERTIFICATE))
    }, [dispatcher])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Create Certificate | Edulyte`
            })
            geCertificateList()
        }
    }, [user?.user, location.search, deleteCert?.message, cloneCert?.message])

    const geCertificateList = async () => {
        setCertificateData((s) => ({ ...s, isLoading: true }));
        const requestDataPayload = await getCertificateListPayload(searchQueryParams)
        try {
            const requestData = {
                params: { userId: user?.user?.userId },
                query: { ...requestDataPayload }
            };
            const response = await certificateService.getUserCertificateList(requestData);
            if (response.status === 200) {
                setCertificateData((s) => ({
                    ...s,
                    data: response.data.data,
                    isLoading: false
                }));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            setCertificateData((s) => ({
                ...s,
                error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
                isLoading: false,
            }));
        }
    };

    const onHandleDeleteCertificate = async (id) => {
        setDeleteCert((s) => ({ ...s, isLoading: true }))
        try {
            const requestData = {
                params: {
                    certificateId: id,
                },
            };
            const response = await certificateService.deleteCertificate(requestData);
            if (response.status === 200) {
                setDeleteCert((s) => ({ ...s, message: response.data?.data?.message }));
                toast.success(response.data?.data?.message)
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            toast.error(error?.response?.data?.message || error?.response?.data?.error || error)
        } finally {
            setDeleteCert((s) => ({ ...s, isLoading: false }))
        }
    };

    const onHandleCloneCertificate = async (id) => {
        setCloneCert((s) => ({ ...s, isLoading: true }))
        try {
            const requestData = {
                params: {
                    certificateId: id,
                },
            };
            const response = await certificateService.cloneCertificate(requestData);
            if (response.status === 200) {
                setCloneCert((s) => ({ ...s, message: response.data?.data?.message }));
                toast.success(response.data?.data?.message)
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error); 
            toast.error(error?.response?.data?.message || error?.response?.data?.error || error)
        } finally {
            setCloneCert((s) => ({ ...s, isLoading: false }))
        }
    };

    const onHandlePageChange = async (page) => {
        searchQueryParams.set(searchParamsInfo.page.key, page)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
    }

    const onHandleSelectMenuItem = async (item, certificate) => {
        if (item?.value === certificateMenuItems?.EDIT?.value) {
            navigate(`/certificate/${certificate?.id}/edit`)
        }
        if (item?.value === certificateMenuItems?.DELETE?.value) {
            const isConfirmed = await confirm("Are you sure you want to delete this certificate? This action is irreversible and the certificate will be permanently lost.");
            if (!isConfirmed) return;
            onHandleDeleteCertificate(certificate?.id)
        }
        if (item?.value === certificateMenuItems?.CLONE?.value) {
            onHandleCloneCertificate(certificate?.id)
        }
        if (item.value === certificateMenuItems.SHARE.value) {
            dispatcher(setShareSocial({
                ...shareSocial,
                url: `${EDULYTE_WEB_DOMAIN_URL}/certificate?certificate_no=${certificate?.certificate_code}`
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
            }))
        }
    }

    const handleCreateCertificate = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_CERTIFICATE_MODAL.key]: {
                ...modal[modalConst.CREATE_CERTIFICATE_MODAL.key],
                isVisible: true,
                title: "Select Certificate Template"
            }
        }))
    }

    const tableRowsConst = certificateData?.data?.result.map((certificate) => ([
        <span>{certificate?.user_name}</span>,
        <span>{selectCategory[certificate?.type]?.label}</span>,
        dayjs(certificate?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
        <span>{certificate?.certificate_code}</span>,
        <span>{certificate?.generated_flow}</span>,
        <span className={cn(certificate?.status === certificateStatusEnum?.issued?.value ? "text-green-600" : certificate?.status === certificateStatusEnum?.draft?.value ? "text-secondary-main" : "")}>{certificate?.status}</span>,
        <div className={"flex items-center justify-start"}>
            <ThreeDotMenu
                menuItems={(certificate?.status === certificateStatusEnum?.issued?.value)
                    ? Object.values(certificateMenuItems)?.filter((menuItem) => ((menuItem?.value !== certificateMenuItems?.EDIT?.value) && (menuItem?.value !== certificateMenuItems?.DELETE?.value)))
                    : Object.values(certificateMenuItems)?.filter((menuItem) => ((menuItem?.value !== certificateMenuItems?.SHARE?.value)))
                }
                onHandleSelect={(option) => onHandleSelectMenuItem(option, certificate)}
            />
        </div>
    ]))

    return (
        <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3 bg-background-light font-bodyPri'>
            <PageHeader pageHeading={pageHeading} />
            <div className='flex justify-between bg-white p-3 rounded'>
                <div>
                    <FilterIcon iconTooltip={"Certificate Filter"} />
                    {/* filter to be added */}
                </div>
                <motion.button
                    whileHover={{ scale: 1.08 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleCreateCertificate}
                    className='px-5 py-2 rounded text-white bg-primary-dark hover:bg-secondary-dark ease-in-out duration-200'
                >
                    Create Certificate
                </motion.button>
            </div>
            <LMSTable
                isLoading={certificateData?.isLoading}
                headers={certificateListHeader}
                rows={tableRowsConst}
                alternateRowColor={"bg-sky-100"}
                rowHeaderColor={"bg-sky-200"}
            />
            {(!certificateData?.isLoading && (certificateData?.data && (certificateData?.data?.pagination?.totalPages > 1))) &&
                <div className={"flex justify-center items-center"}>
                    <Pagination
                        page={certificateData?.data?.pagination?.page}
                        totalPages={certificateData?.data?.pagination?.totalPages}
                        onChangePage={onHandlePageChange}
                    />
                </div>
            }
            {(!certificateData?.isLoading && certificateData?.error) &&
                <div className={'w-full flex items-center justify-center'}>
                    <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
                        {certificateData.error}
                    </span>
                </div>
            }
            <ConfirmDialog confirmBtnText={"Delete now"} title='Are you sure?' />
        </div>
    )
}

export default CreateCertificate
