import { useState, memo } from "react";

import Menu from "@mui/material/Menu";
import IconButton from '@mui/material/IconButton';
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";

import { BsThreeDotsVertical } from "react-icons/bs";

import { cn } from "utils/cn.utils";

const useStyles = makeStyles({
    root: {},
});

const ITEM_HEIGHT = 60;

const ThreeDotMenu = memo(({
    iconClassName = "",
    menuItems,
    onHandleSelect
}) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onHandleCloseMenu = () => {
        setAnchorEl(null)
    }
    const handleClose = (ele) => {
        setAnchorEl(null);
        onHandleSelect(ele)
    };

    return (
        <div className={""}>
            <IconButton
                aria-label={"more"}
                id={"long-button"}
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup={"true"}
                onClick={handleClick}
            >
                <BsThreeDotsVertical className={cn("text-xl md:text-2xl text-text-800 cursor-pointer", iconClassName)} />
            </IconButton>
            <Menu
                id={"long-menu"}
                anchorEl={anchorEl}
                open={open}
                onClose={onHandleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                classes={{
                    root: classes.root,
                }}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '18ch',
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {Object.values(menuItems).map((ele, idx) =>
                    <MenuItem key={idx} onClick={() => handleClose(ele)} value={ele.label}>
                        {ele.label}
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
});

export default ThreeDotMenu;