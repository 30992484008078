import { Fragment, memo } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { cn } from "utils/cn.utils";
import { AiOutlineClose } from "react-icons/ai";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import CreateLinkHubModalContent from "pages/auth/profile/linkInBio/createLinkHubModalContent";

const CreateLinkHubModal = memo(({
    openModal = false,
    bgColor = "bg-white",
    title,
}) => {
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const closeModal = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LINK_HUG_MODAL.key]: {
                ...modal[modalConst.CREATE_LINK_HUG_MODAL.key],
                isVisible: false,
                title: "",
            }
        }))
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className={"fixed max-h-screen inset-0 z-50 overflow-y-auto"}
                open={openModal}
                onClose={closeModal}
            >
                <div className={"p-0 md:p-5 text-center"}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={"fixed inset-0 bg-black opacity-50"} />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden={"true"} className={"inline-block h-screen align-middle"}>
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform max-w-xl ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full h-full overflow-x-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    <div className={"flex w-full justify-between items-center bg-background-light h-16 px-5 drop-shadow-sm"}>
                                        <p className={"font-medium text-text-900 text-lg"}>
                                            {title}
                                        </p>
                                        <AiOutlineClose
                                            onClick={closeModal}
                                            className={'cursor-pointer'}
                                            size={20}
                                        />
                                    </div>
                                    <CreateLinkHubModalContent />
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
});

export default CreateLinkHubModal;