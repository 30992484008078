import { useEffect, useRef, useState } from 'react';
import { FiCopy, FiEdit2, FiShare } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';
import { MdClose, MdOutlineGridView } from 'react-icons/md';
import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import { motion } from 'framer-motion';
import { Tooltip } from '@mui/material';
import { cn } from 'utils/cn.utils';
import linkTreeService from 'redux/linkTree/linkTree.service';
import { toast } from 'react-toastify';
import { debounce } from 'utils/generators.utils';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';

const initialState = { isLoading: false, result: null, error: null };

const EditSlug = ({ linkTree }) => {
    const { modal, shareSocial } = useAppState((state) => state.local)
    const [updatedSlug, setUpdatedSlug] = useState(initialState);
    const [editSlug, setEditSlug] = useState("loading...");
    const [isEditing, setIsEditing] = useState(false);
    const [copied, setCopied] = useState(false);
    const [error, setError] = useState("");
    const inputRef = useRef(null);
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        setEditSlug(linkTree?.slug);
    }, [linkTree?.slug]);

    const handleOnChange = (e) => {
        setEditSlug(e.target.value);
        setError(validateSlug(e.target.value));
        const delayFunction = debounce.debounce(handleUpdateSlugRequest)
        delayFunction("slug", e.target.value,)
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${EDULYTE_WEB_DOMAIN_URL}/link/${editSlug}`);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleEdit = () => {
        setIsEditing(true);
        if (!isEditing) {
            setUpdatedSlug(initialState)
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.select();
            }, 0);
        }
    };

    const handleOnBlur = () => {
        setIsEditing(false)
        if (updatedSlug?.error) {
            setEditSlug(linkTree?.slug)
        }
    }

    const validateSlug = (value) => {
        const regex = /^[a-zA-Z0-9-_]+$/;
        if (value.length > 20) {
            return 'Slug cannot be more than 20 characters';
        }
        if (!regex.test(value)) {
            return 'Slug can only contain letters, numbers, hyphens, and underscores';
        }
        return '';
    };

    const handleUpdateSlugRequest = async (field, value) => {
        const validationError = validateSlug(value);
        if (validationError) {
            setError(validationError);
            return;
        }
        setUpdatedSlug({ ...initialState, isLoading: true });
        try {
            const requestData = {
                params: {
                    linkTreeId: linkTree?.id
                },
                body: {
                    [field]: value
                },
            };
            const response = await linkTreeService.updateLinktree(requestData);
            if (response.status === 200) {
                setUpdatedSlug((s) => ({ ...s, result: response?.data?.data?.result }));
                setError("");
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            setUpdatedSlug((s) => ({
                ...s, error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
            }));
            setEditSlug(linkTree?.slug)
        } finally {
            setUpdatedSlug((s) => ({ ...s, isLoading: false }));
        }
    };

    const handleShare = () => {
        dispatcher(setShareSocial({
            ...shareSocial,
            url: `${EDULYTE_WEB_DOMAIN_URL}/link/${editSlug}`
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }

    return (
        <div className='flex flex-col gap-4 p-5 border rounded-md bg-white w-full'>
            <p className='font-semibold text-lg text-background-black'>{"Your Unique Link"}</p>
            <div className='flex flex-col'>
                <div className='flex items-center border rounded pr-2 text-text-700 focus-within:border-primary-main overflow-hidden'>
                    <span className={cn('text-sm pl-2 py-2', isEditing ? "bg-text-200" : "")}>{`${EDULYTE_WEB_DOMAIN_URL}/link/`}</span>
                    <input
                        ref={inputRef}
                        className="w-full bg-transparent outline-none pr-2"
                        value={editSlug}
                        onChange={handleOnChange}
                        disabled={!isEditing}
                        onBlur={handleOnBlur}
                    />
                    {updatedSlug.isLoading ? (
                        <FaSpinner className={"animate-spin text-lg text-primary-main"} />
                    ) : (
                        <>
                            {isEditing ? (
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className='hover:text-secondary-dark'
                                    onClick={handleOnBlur}
                                >
                                    <MdClose className='text-lg' />
                                </motion.button>
                            ) : (
                                <Tooltip title="Edit Slug" arrow>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className='hover:text-secondary-dark'
                                        onClick={handleEdit}
                                    >
                                        <FiEdit2 className='text-lg' />
                                    </motion.button>
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
                {error && <p className="text-secondary-dark text-sm mt-2">{error}</p>}
                {updatedSlug?.error && <p className="text-secondary-dark text-sm mt-2">{updatedSlug?.error}</p>}
            </div>
            <div className='flex items-center gap-3'>
                <Tooltip title={"View All Links"} arrow>
                    <motion.button
                        whileHover={{ scale: 1.095 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={{}}
                        className='h-10 w-10 flex items-center justify-center gap-2 rounded-full shadow hover:shadow-lg border border-text-100 text-primary-main hover:text-secondary-dark ease-in-out duration-200'
                    >
                        <MdOutlineGridView className='text-lg' />
                    </motion.button>
                </Tooltip>
                <Tooltip title={copied ? "Copied!" : "Copy link to clipboard"} arrow>
                    <motion.button
                        whileHover={{ scale: 1.095 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={handleCopyLink}
                        className='h-10 w-10 flex items-center justify-center gap-2 rounded-full shadow hover:shadow-lg border border-text-100 text-primary-main hover:text-secondary-dark ease-in-out duration-200'
                    >
                        <FiCopy className='text-lg' />
                    </motion.button>
                </Tooltip>
                <Tooltip title={"Share Link"} arrow>
                    <motion.button
                        whileHover={{ scale: 1.095 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={handleShare}
                        className='h-10 w-10 flex items-center justify-center gap-2 rounded-full shadow hover:shadow-lg border border-text-100 text-primary-main hover:text-secondary-dark ease-in-out duration-200'
                    >
                        <FiShare className='text-lg' />
                    </motion.button>
                </Tooltip>
            </div>
        </div>
    );
};

export default EditSlug;
