import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { cn } from 'utils/cn.utils'

import { useAppState } from 'hooks/useStore'
import certificateService from 'redux/certificate/certificate.service'
import { pagesInfo } from 'utils/pagesInfo'


const CreateCertificateModalContent = ({ closeModal, certificatePayload = null }) => {
    const { user } = useAppState(state => state.user)

    const [certificate, setCertificate] = useState({ isLoading: false, data: null, error: null })
    const [templates, setTemplates] = useState({ isLoading: false, data: null, error: null })
    const [selectedTemplate, SetSelectedTemplate] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        handleGetTemplate()
    }, [])

    const handleGetTemplate = async () => {
        setTemplates((s) => ({ ...s, isLoading: true }));
        try {
            const response = await certificateService.getCertificateTemplateList();
            if (response.status === 200) {
                setTemplates((s) => ({
                    ...s,
                    data: response.data.data,
                    isLoading: false,
                }));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            setTemplates((s) => ({
                ...s,
                error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
                isLoading: false,
            }));
        }
    };

    const handleCreateCertificate = async () => {
        setCertificate((s) => ({ ...s, isLoading: true }));
        try {
            let payload = {
                owner_user_id: user?.user?.userId,
                template_id: selectedTemplate?.id,
            }
            if (certificatePayload) {
                payload = { ...payload, ...certificatePayload }
            }
            const requestData = {
                body: { ...payload },
            };
            const response = await certificateService.createCertificate(requestData);
            if (response.status === 201) {
                navigate(`${pagesInfo.EDIT_CERTIFICATE.pagePath}/${response?.data?.data?.result?.id}/edit`)
                closeModal()
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            setCertificate((s) => ({
                ...s,
                error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
                isLoading: false,
            }));
        }
    };

    return (
        <div className='p-10 space-y-5 flex flex-col justify-between min-h-[26rem]'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10'>
                {templates?.isLoading && new Array(3).fill("")?.map((_, index) => (
                    <div key={index} className='flex w-60 flex-col items-center justify-center gap-3 bg-background-light rounded p-5'>
                        <div className='h-48 w-32 bg-slate-200 animate-pulse' />
                        <div className='h-6 w-full bg-slate-200 animate-pulse' />
                    </div>
                ))}
                {!templates?.isLoading && templates?.data?.result?.map((template, index) => (
                    <motion.div
                        key={index}
                        whileHover={{ scale: 1.09 }}
                        onClick={() => SetSelectedTemplate(template)}
                        className={cn('flex flex-col justify-center items-center hover:shadow p-5 gap-3 rounded group cursor-pointer ease-in-out duration-300',
                            "bg-background-light border-2 border-background-light",
                            selectedTemplate?.id === template?.id && "border-primary-dark"
                        )}>
                        <img
                            className='h-48 aspect-auto object-contain'
                            src={template?.image_url}
                        />
                        <p className='text-background-black font-medium group-hover:text-primary-dark text-center'>{template?.title}</p>
                    </motion.div>
                ))}
            </div>
            <div className='flex justify-end'>
                <motion.button
                    whileHover={{ scale: 1.09 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleCreateCertificate}
                    disabled={!selectedTemplate?.id || certificate?.isLoading}
                    className={cn('px-5 py-2 rounded-full ease-in-out duration-200 text-white',
                        !selectedTemplate?.id || certificate?.isLoading ? "bg-background-darkLight" : "bg-primary-dark hover:bg-secondary-dark",
                    )}
                >
                    Continue
                </motion.button>
            </div>
        </div>
    )
}

export default CreateCertificateModalContent
