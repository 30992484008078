import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { FiShare } from "react-icons/fi";

import ToolTipView from 'components/tooltipView'
import SelectCategory from './selectCategory'
import IssuerDetails from './issuerDetails'
import UserDetails from './userDetails'
import StatusButton from './StatusButton'
import { certificateStatusEnum } from '../../data'
import certificateService from 'redux/certificate/certificate.service'
import { toast } from 'react-toastify'
import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import { modalConst } from 'redux/local/local.const';
import { useConfirm } from 'hooks/useConfirm';

const initialState = { isLoading: false }

const SideHeader = ({ certificate, setCertificateDetails }) => {
    const { modifyUserConfig } = useAppState((state) => state.user)
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { confirm, ConfirmDialog } = useConfirm();

    const [issue, setIssue] = useState(initialState)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleUpdateStatus = async (option) => {
        if (modifyUserConfig?.isLoading) return
        if (option?.value === certificateStatusEnum.issued.value) {
            const isConfirmed = await confirm("You won’t be able to modify it after the issue. Recipient will receive an email to download this certificate. or you can share");
            if (!isConfirmed) return;
        }

        if (issue?.isLoading) return
        setIssue({ ...initialState, isLoading: true });

        try {
            const requestData = {
                params: {
                    certificateId: certificate?.id
                },
                body: {
                    status: option?.value,
                    type: certificate?.type,
                    user: {
                        user_id: certificate?.user,
                        print_name: certificate?.placeholder?.user_name
                    },
                    [certificate?.type]: certificate[certificate?.type]
                },
            };
            const response = await certificateService.updateCertificate(requestData);
            if (response.status === 200) {
                setCertificateDetails(s => ({ ...s, result: response.data.data.result }))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        } finally {
            setIssue((s) => ({ ...s, isLoading: false }))
        }
    }

    const handleShare = () => {
        dispatcher(setShareSocial({
            ...shareSocial,
            url: `${EDULYTE_WEB_DOMAIN_URL}/certificate?certificate_no=${certificate?.certificate_code}`
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }

    return (
        <>
            <div className={"p-5 w-full space-y-5 sticky top-0 z-30"}>
                <div className={"w-full flex items-center justify-between gap-3 md:gap-5 border-b border-text-300 pb-5"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <ToolTipView content={"Back"}>
                            <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={() => navigate(-1)}>
                                <IoMdArrowRoundBack className={'text-lg md:text-xl text-background-dark'} />
                            </button>
                        </ToolTipView>
                        <Link to={`/resources/certificate`} className={"group"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-base underline tracking-wide line-clamp-1 group-hover:text-secondary-dark group-hover:underline cursor-pointer"}>
                                {"Certificates"}
                            </span>
                        </Link>
                    </div>
                    <div className='flex items-center gap-3'>
                        {certificate?.status === certificateStatusEnum.issued.value &&
                            <ToolTipView content='Share Certificate'>
                                <button onClick={handleShare} className='text-lg text-text-600 hover:text-secondary-main'><FiShare /></button>
                            </ToolTipView>}
                        <StatusButton
                            isLoading={issue?.isLoading}
                            disabled={certificate?.status === certificateStatusEnum.issued.value}
                            optionsList={Object.values(certificateStatusEnum)}
                            loaderBtnClassName={`bg-${certificateStatusEnum[certificate?.status]?.darkColor}`}
                            selectedBtnClassName={`text-white bg-${certificateStatusEnum[certificate?.status]?.darkColor}`}
                            dropdownBtnClassName={`text-white bg-${certificateStatusEnum[certificate?.status]?.darkColor}`}
                            selectedOption={
                                certificate?.status === certificateStatusEnum.draft.value
                                    ? certificateStatusEnum.draft.label
                                    : certificate?.status === certificateStatusEnum.issued.value
                                        ? "Issued"
                                        : certificateStatusEnum.issued.label
                            }
                            onHandleUpdateStatus={onHandleUpdateStatus}
                        />
                    </div>
                </div>
                <div className='flex w-full flex-col gap-5'>
                    <UserDetails certificate={certificate} setCertificateDetails={setCertificateDetails} />
                    <SelectCategory certificate={certificate} setCertificateDetails={setCertificateDetails} />
                    <IssuerDetails certificate={certificate} setCertificateDetails={setCertificateDetails} />
                </div>
            </div>
            <ConfirmDialog confirmBtnText={"Issue now"} title='Are you sure?' color="warning" />
        </>
    )
}

export default SideHeader
