import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';
import Footer1 from 'components/footer1/Footer1';

import { PageContentStyle } from './TutorCoursePage.style';
import TutorInfoCard from './components/TutorInfoCard';
import TutorCourseList from './components/TutorCourseList';
import TutorReviewList from './components/TutorReviewList';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorUserPublicProfileByUserId } from 'redux/tutor/tutor.request';
import { getPublicTutorCourseList } from "redux/course/course.request";
import { getTutorReviewListByTutorId } from "redux/review/review.request";

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearTutorPublicProfile } from "redux/tutor/tutor.slice";
import { setClearTutorReviewList } from 'redux/review/review.slice';
import { setClearPublicTutorCourseList } from "redux/course/course.slice";

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const TutorCourse = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { tutorPublicProfile } = useAppState((s) => s.tutor)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const { userId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR_COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (userId) {
            dispatcher(getTutorUserPublicProfileByUserId(Number(userId)))
        }

        return () => {
            dispatcher(setClearTutorPublicProfile())
        }
    }, [userId])

    useEffect(() => {
        if (tutorPublicProfile?.tutorPublicProfile?.tutorId) {
            const query = generateQueryParams(location.search)

            dispatcher(getPublicTutorCourseList(tutorPublicProfile?.tutorPublicProfile?.tutorId, { page: query?.pn || 1, records: 12 }))
            dispatcher(getTutorReviewListByTutorId(tutorPublicProfile?.tutorPublicProfile?.tutorId))
        }

        return () => {
            dispatcher(setClearPublicTutorCourseList())
            dispatcher(setClearTutorReviewList())
        }
    }, [location.search, tutorPublicProfile?.tutorPublicProfile])

    useEffect(() => {
        if (tutorPublicProfile?.tutorPublicProfile) {
            setTitle({
                ...title,
                title: `Classes Offered by ${tutorPublicProfile?.tutorPublicProfile?.firstName} | Book Now!`,
                description: tutorPublicProfile?.tutorPublicProfile?.bio
            })
        }
    }, [tutorPublicProfile?.tutorPublicProfile])

    if (tutorPublicProfile?.isLoading) {
        return (
            <FullPageLoader isLoading={tutorPublicProfile?.isLoading} />
        )
    }

    if (tutorPublicProfile?.errorMsg) {
        return (
            <div className={"w-full h-full flex items-center justify-center"}>
                <span className={"font-bodyPri font-semibold text-text-800 text-md"}>
                    {tutorPublicProfile?.errorMsg}
                </span>
            </div>
        )
    }

    return (
        <PageContentStyle>
            <div className={'w-full mx-auto container'}>
                <div className={"grid grid-cols-12 gap-5 p-5"}>
                    <div className={"relative col-start-1 col-span-12 lg:col-start-1 lg:col-span-4"}>
                        <TutorInfoCard />
                    </div>

                    <div className={"col-start-1 col-span-12 lg:col-start-5 lg:col-span-8 rounded-lg space-y-5"}>
                        <TutorCourseList />
                        <TutorReviewList />
                    </div>
                </div>
            </div>
            <Footer1 />
        </PageContentStyle>
    )
}

export default TutorCourse;