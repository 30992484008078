import { DEFAULT_LMS_COURSE_PAGE, DEFAULT_LMS_COURSE_RECORDS } from "redux/edulyteLms/lmsCourse/lmsCourse.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

export const pageHeading = {
  heading: "My Courses",
  subHeading: "Find the list of courses",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const lmsCourseTableHeaderConst = [
  "Title",
  "Status",
  "Created Date",
  "Actions",
]

export const lmsCourseMenuItems = {
  INVITE: {
    label: "Invite",
    value: "invite"
  },
  SHARE: {
    label: "Share",
    value: "share"
  },
  COPY: {
    label: "Copy",
    value: "copy"
  },
  PARTICIPANTS: {
    label: "Participants",
    value: "participants"
  },
  EDIT: {
    label: "Edit",
    value: "edit"
  },
  CERTIFICATE: {
    label: "Issue certificate",
    value: "certificate"
  },
}

export const searchParamsInfo = {
  page: {
    key: "page"
  },
  records: {
    key: "records"
  },
  title: {
    key: "title"
  },
  status: {
    key: "status"
  },
  date: {
    key: "date"
  },
  minCreatedAt: {
    key: "minCreatedAt"
  },
  maxCreatedAt: {
    key: "maxCreatedAt"
  }
}

export const lmsCourseFilters = {
  [searchParamsInfo.title.key]: {
    key: "title",
    label: "Title"
  },
  [searchParamsInfo.status.key]: {
    key: "status",
    label: "Status"
  },
  [searchParamsInfo.date.key]: {
    key: "date",
    label: "Date"
  }
}

export const getLmsCourseListPayload = async (searchQueryParams) => {

  let requestDataPayload = {
    page: searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_LMS_COURSE_PAGE,
    records: searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_LMS_COURSE_RECORDS,
    timeZone: timeZone
  }
  if (!!searchQueryParams.get(searchParamsInfo.title.key)) {
    requestDataPayload[searchParamsInfo.title.key] = searchQueryParams.get(searchParamsInfo.title.key).replaceAll("-", " ")
  }
  if (!!searchQueryParams?.getAll(searchParamsInfo.status.key)?.length) {
    requestDataPayload[searchParamsInfo.status.key] = searchQueryParams?.getAll(searchParamsInfo.status.key)?.map((item) => item?.replaceAll("-", "_"))?.join(",")
  }
  if (!!searchQueryParams.get(searchParamsInfo.minCreatedAt.key)) {
    requestDataPayload[searchParamsInfo.minCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.minCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
  }
  if (!!searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)) {
    requestDataPayload[searchParamsInfo.maxCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
  }

  return requestDataPayload;
}